import React from 'react';

const FormControls = (props) => {
    return (
        <div className="form--controls">
            <div>
                {!props.formSubmitting && (
                    <button
                        className="btn primary"
                        onClick={() => props.onClick()}
                    >
                        Submit
                    </button>
                )}
                {props.formSubmitting && (
                    <button className="btn disabled">
                        {props.formSubmitted ? 'Thank You' : 'Please Wait'}
                    </button>
                )}
                {props.progress > 0 && (
                    <div className="form--progress--bar">
                        <div style={{ width: props.progress + '%' }}></div>
                    </div>
                )}
                <div className="form--errors">
                    {props.progress === 0 &&
                        !props.formSubmitting &&
                        props.hasErrors.length > 0 && (
                            <div>
                                Please Check for Errors.
                                <br />
                                <span>(Required fields are marked in red)</span>
                            </div>
                        )}
                    {props.progress === 0 && props.formSubmitted && (
                        <div className="form--submit--complete">
                            Your Message Has Been Sent
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FormControls;
