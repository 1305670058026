import React from 'react';
import ContactTile from '../../contact/contact_tiles/contact_tile';
import PowerfulMarketing from '../whatwedo_tiles/powerful_marketing';
import TrustLoyalty from '../whatwedo_tiles/trust_loyalty';
import UserExperience from '../whatwedo_tiles/user_experience';
import WhatWeDoIntro from '../whatwedo_tiles/whatwedo_intro';

// Default Layout
// caption: --Main info
// text: --Alt Info
// button: --Button text
// path: --React Router Path
// type: --Tile Type
// background: --SVG Background pulled via import
// background-image : --Image address
// component: <Component />

const whatWeDoInfo = [
    {
        type: 'component',
        component: <WhatWeDoIntro />,
    },
    {
        type: 'component',
        component: <UserExperience />,
    },
    {
        type: 'component',
        component: <PowerfulMarketing />,
    },
    {
        type: 'component',
        component: <TrustLoyalty />,
    },
    {
        type: 'component',
        component: <ContactTile />,
    },
];

export default whatWeDoInfo;
