import {
    _q,
    _hasClass,
    _addClass,
    _removeClass,
} from '../functions/alisma_library_react';
const toggleHeaderTheme = ({ logo, menu }) => {
    const logoTitle = _q('.alisma-title');
    const menuBars = _q('.menu-bars');

    const logoLight = 'alisma-title-light',
        logoDark = 'alisma-title-dark',
        logoBlack = 'alisma-title-black',
        logoWhite = 'alisma-title-white';
    const menuWhite = 'menu-bars-white',
        menuBlack = 'menu-bars-black',
        menuLight = 'menu-bars-light',
        menuDark = 'menu-bars-dark';

    function removeLogoTheme() {
        _removeClass(logoTitle, logoLight);
        _removeClass(logoTitle, logoDark);
        _removeClass(logoTitle, logoBlack);
        _removeClass(logoTitle, logoWhite);
    }
    function removeMenuTheme() {
        _removeClass(menuBars, menuBlack);
        _removeClass(menuBars, menuWhite);
        _removeClass(menuBars, menuDark);
        _removeClass(menuBars, menuLight);
    }

    if (logo) {
        if (!_hasClass(logoTitle, 'alisma-title-' + logo)) {
            removeLogoTheme();
            _addClass(logoTitle, 'alisma-title-' + logo);
        }
    }
    if (menu) {
        if (!_hasClass(menuBars, 'menu-bars-' + menu)) {
            removeMenuTheme();
            _addClass(menuBars, 'menu-bars-' + menu);
        }
    }
};
export default toggleHeaderTheme;
