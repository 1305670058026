import React from 'react';
import TextAreaRaw from '../../../../components/inputs/textAreaRaw';
import TextInput from '../../../../components/inputs/textInput';
// import headerImage from '../../../../assets/images/pages/giveaway/alisma_flyer_giveaway2304_header_230414_1.0@2x.png';

function FormContent(props) {
    return (
        <div className="form-content">
            <h1 className="form-header">
                {/* <img
                    src={headerImage}
                    alt="Alisma Giveaway!"
                    style={{ height: '30vh' }}
                /> */}
            </h1>
            <div className="form-elements">
                <div className="form-element-group">
                    <TextInput
                        id="name"
                        label="My name is:"
                        placeholder="Your Name"
                        value={props.formInfo.name || ''}
                        required
                        hasErrors={props.hasErrors}
                        onChange={(value) => props.onChange('name', value)}
                    />
                    {/* <TextInput
                        id="phone"
                        label="My phone number is:"
                        placeholder="Your phone number"
                        value={props.formInfo.phone || ''}
                        hasErrors={props.hasErrors}
                        onChange={(value) => props.onChange('phone', value)}
                    /> */}
                    <TextInput
                        id="email"
                        label="My email is:"
                        placeholder="Your email address"
                        required
                        value={props.formInfo.email || ''}
                        hasErrors={props.hasErrors}
                        onChange={(value) => props.onChange('email', value)}
                    />
                    <TextInput
                        id="design"
                        label="Design I want: (optional)"
                        placeholder="Your design of choice"
                        value={props.formInfo.design || ''}
                        hasErrors={props.hasErrors}
                        onChange={(value) => props.onChange('design', value)}
                    />
                </div>
                <div className="form-element-group">
                    {/*<TextAreaRaw
                        id="message"
                        label="What have to say is:"
                        placeholder="Type your message here..."
                        size="lg"
                        value={props.formInfo.message || ''}
                        required
                        hasErrors={props.hasErrors}
                        onChange={(value) => props.onChange('message', value)}
                    />*/}
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default FormContent;
