import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../components/headers/header';
import Home from '../pages/home/Home';
import WhatWeDo from '../pages/whatwedo/WhatWeDo';
import About from '../pages/about/About';
import OurWork from '../pages/ourwork/OurWork';
import Contact from '../pages/contact/Contact';
import WebsitesPortfolio from '../pages/ourwork/ourwork_tiles/websites_portfolio/WebsitesPortfolio';
import GraphicsPortfolio from '../pages/ourwork/ourwork_tiles/graphics_portfolio/GraphicsPortfolio';
import Clays from '../components/paths/work/claysrestaurant';
import HoofHaulers from '../components/paths/work/hoofhaulers';
import DigitalMarketingPortfolio from '../pages/ourwork/ourwork_tiles/digitalMarketing_portfolio/DigitalMarketingPortfolio';
import TimeConverter from '../pages/_extras/time_converter/TimeConverter';
import PasswordGenerator from '../pages/_extras/password_generator/PasswordGenerator';
import Giveaway2304 from '../pages/giveaway/Giveaway2304';

class ReactRouter extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/what-we-do" element={<WhatWeDo />} />
                    <Route path="/our-work" element={<OurWork />} />
                    <Route
                        path="/our-work/websites"
                        element={<WebsitesPortfolio />}
                    />
                    <Route
                        path="/our-work/graphics"
                        element={<GraphicsPortfolio />}
                    />
                    <Route
                        path="/our-work/digital-marketing"
                        element={<DigitalMarketingPortfolio />}
                    />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/giveaway2304" element={<Giveaway2304 />} />
                    <Route
                        path="/our-work/claysrestaurant"
                        element={<Clays />}
                    />
                    <Route
                        path="/our-work/hoofhaulers"
                        element={<HoofHaulers />}
                    />
                    {/* <Route path="/restaurants/claysrestaurant" component={Clays} />
                <Route
                    path="/dealerships/hoofhaulers"
                    component={HoofHaulers}
                /> */}
                    <Route
                        path="/password-generator"
                        element={<PasswordGenerator />}
                    />
                    <Route path="/time-converter" element={<TimeConverter />} />
                </Routes>
            </React.Fragment>
        );
    }
}

export default ReactRouter;
