import React from 'react';

export default class MultiText extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loaded: false,
            multiText: false,
            multiTextClass: '',
            textInView: 0
        };
        this.state = this.initialState;
        this.multiTextTimer = '';
    }
    componentDidMount() {
        this.setState({
            loaded: true,
            text: this.props.text,
            multiText: this.props.multiText
        });
    }
    componentWillUnmount() {
        clearTimeout(this.multiTextTimer);
    }

    render() {
        const { loaded } = this.state;

        if (!loaded) {
            return <span>...</span>;
        }
        clearTimeout(this.multiTextTimer);

        //  !Timeout to fade out text
        this.multiTextTimer = setTimeout(() => {
            this.setState({ multiTextClass: 'fade' });

            //  !Timeout to fade in text and progress text in view by one
            setTimeout(() => {
                if (this.state.textInView < this.state.multiText.length - 1) {
                    this.setState({
                        textInView: this.state.textInView + 1,
                        multiTextClass: ''
                    });
                } else {
                    this.setState({ textInView: 0, multiTextClass: '' });
                }
            }, 1000);
        }, 4000);
        return (
            <React.Fragment>
                {this.state.text}{' '}
                <span className={this.state.multiTextClass}>
                    {this.props.multiText[this.state.textInView]}
                </span>
            </React.Fragment>
        );
    }
}
