import React from 'react';

export default class TotalInput extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            weekTotal: '0 hrs',
        };
        this.state = this.initialState;
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.setState({
            weekTotal: this.props.weekTotal,
        });
    }
    componentWillReceiveProps(props) {
        const value = String(props.weekTotal);

        const hasPartial = value.indexOf('.');

        const wholeHours = value.slice(
            0,
            hasPartial < 0 ? 99 : value.indexOf('.')
        );
        const partialHours =
            hasPartial < 0 ? 0 : value.slice(value.indexOf('.'), 99) * 60;
        const total = wholeHours + 'hrs ' + partialHours.toFixed(0) + 'min';

        this.setState({
            weekTotal: total,
        });
    }
    handleChange(e) {
        // this.props.onChange(e.target.value, this.state.day);
    }
    render() {
        const style = {
            display: 'inline-block',
            width: 300,
            fontFamily: 'Verdana',
            fontSize: '14px',
            textAlign: 'center',
            padding: 8,
        };

        const { weekTotal } = this.state;

        return (
            <div className="total-input" style={style}>
                Total: {weekTotal}
            </div>
        );
    }
}
