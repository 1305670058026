import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Content from '../../../../components/content/content';
import websitesPortfolioInfo from './websites_info/websites_portfolio_info';

class WebsitesPortfolio extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>A L I S M A - Our Work - Websites</title>
                    <meta
                        name="description"
                        content="Imagine easily being able to reach intended audiences and increasing brand recognition to bring in new customers."
                    />
                </Helmet>
                <Content info={websitesPortfolioInfo} className="our-work" />
            </React.Fragment>
        );
    }
}

export default WebsitesPortfolio;
