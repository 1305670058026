import React, { Component } from 'react';
import Content from '../../content/content';
import hoofhaulersinfo from '../../content/infos/case_infos/hoofhaulersinfo';

class HoofHaulers extends Component {
    render() {
        return (
            <React.Fragment>
                <Content info={hoofhaulersinfo} className="cs-hoofhaulers" />
            </React.Fragment>
        );
    }
}

export default HoofHaulers;
