import React, { useEffect } from 'react';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';

const OurVision = (props) => {
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'dark', menu: 'light' });
            if (window.innerWidth < 400) {
                toggleHeaderTheme({ menu: 'black' });
            }
        }
    });
    return (
        <section className="tile--section about-our-vision">
            <div className="tile--section--main--container">
                <div className="tile--section--inner--container">
                    <div className="main--info--left tile--info--dark">
                        <p>Our Vision</p>
                    </div>
                    <div className="side--info--left tile--info--dark">
                        <p>
                            We believe in transparency. We provide upfront
                            pricing, honest communication, and clear cut
                            timelines to allow for streamlined projects.
                        </p>
                    </div>
                </div>
                <div className="tile--section--inner--container tile--section--media--container">
                    <div>
                        {/* <img
                            src={_pathImg + 'pages/home/photo_group_8.png'}
                            alt=""
                        /> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurVision;
