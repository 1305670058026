import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { _q } from '../../../functions/alisma_library_react';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';
import AlismaSplat from '../../../components/logo/alisma_splat.alt';
import ScrollWindowIndicator from '../../../components/scroll_window_indicator';
import firstContactThumb from '../../../assets/videos/home/alisma_home_thumb.png';
import firstContactVideo from '../../../assets/videos/home/alisma_homepage_230121_1.2.mp4';

const FirstContact = (props) => {
    const [showVideo, setShowVideo] = useState(false);
    const [initiateSplat, setInitiateSplat] = useState(false);
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'light', menu: 'white' });
            if (!initiateSplat) {
                setTimeout(() => {
                    setInitiateSplat(true);
                }, 500);
            }
            if (_q('.home-first-contact-video').paused && showVideo) {
                _q('.home-first-contact-video').play();
            }
        } else {
            if (!_q('.home-first-contact-video').paused && showVideo) {
                _q('.home-first-contact-video').pause();
            }
        }
    });
    const toggleVideo = () => {
        setShowVideo(true);
        props.inView && _q('.home-first-contact-video').play();
    };
    return (
        <section className="tile--section home-first-contact">
            <div className="tile--section--main--container">
                <video
                    className={
                        'home-first-contact-video' + (showVideo ? ' in' : '')
                    }
                    poster={firstContactThumb}
                    loop
                    muted
                    preload="metadata"
                    playsInline
                >
                    <source src={firstContactVideo} type="video/mp4" />
                    Your browser does not support HTML5 video.
                </video>

                <div className="tile--section--inner--container">
                    <div>
                        {initiateSplat && (
                            <AlismaSplat
                                onMotionComplete={() => toggleVideo(true)}
                            />
                        )}
                    </div>
                </div>
                <div className="tile--section--inner--container">
                    <div className="side--info--left tile--info--light">
                        <p>We Build Custom Websites and Graphics.</p>
                        <NavLink to="/our-work" title="Our Work">
                            <button className="button">Our Work</button>
                        </NavLink>
                    </div>
                </div>
            </div>
            <ScrollWindowIndicator show={props.inView} />
        </section>
    );
};

export default FirstContact;
