import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';
import backgroundImage from '../../../assets/images/pages/home/next_level_stairs.jpeg';

const OurCommitment = (props) => {
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'light', menu: 'white' });
        }
    });
    return (
        <section className="tile--section home-our-commitment">
            <div
                className="tile--section--main--container"
                style={{
                    backgroundImage: 'url(' + backgroundImage + ')',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className="tile--section--inner--container">
                    <div className="main--info--left tile--info--light">
                        <p>Our dedication to our customers is un-matched.</p>
                    </div>
                    <div className="side--info--left tile--info--light">
                        <p>
                            Our accountability and attention to detail is
                            paramount for our clients, and we pride ourselves in
                            building long-term relationships with them.
                        </p>
                        <NavLink to="/what-we-do">
                            <button className="button">Discover How</button>
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurCommitment;
