import React, { useEffect, useState } from 'react';
import CheckBoxItem from '../../../components/inputs/CheckBox/CheckBoxItem';
// import { _randGenerator } from '../../../functions/alisma_library_react';
import backgroundImage from '../../../assets/images/pages/_extras/password_generator/binary_background.jpg';
import Select from '../../../components/inputs/Select/Select';
import passGenerator from './functions/pass_gen';

const PassGenContent = (props) => {
    const [sectionLength, setSectionLength] = useState(4);
    const [numberOfSections, setNumberOfSections] = useState(3);
    const [typeOfPassword, setTypeOfPassword] = useState('Sectional');
    const [passGenResult, setPassGenResult] = useState('');

    const [includeCapitals, setIncludeCapitals] = useState(true);
    const [includeNumbers, setIncludeNumbers] = useState(true);
    const [includeSymbols, setIncludeSymbols] = useState(false);
    const [excludeAmbiguous, setExcludeAmbiguous] = useState(false);
    const [hasBeenCopied, setHasBeenCopied] = useState(false);

    useEffect(() => {
        const result = passGenerator({
            includeCapitals: includeCapitals,
            includeNumbers: includeNumbers,
            includeSymbols: includeSymbols,
            excludeAmbiguous: excludeAmbiguous,
            numberOfSections: numberOfSections,
            sectionLength: sectionLength,
        });
        setPassGenResult(result);
    }, [
        includeCapitals,
        includeNumbers,
        includeSymbols,
        excludeAmbiguous,
        numberOfSections,
        sectionLength,
    ]);
    // useEffect(() => {
    //     generatePassword();
    // }, []);

    const generatePassword = () => {
        setHasBeenCopied(false);

        const result = passGenerator({
            includeCapitals: includeCapitals,
            includeNumbers: includeNumbers,
            includeSymbols: includeSymbols,
            excludeAmbiguous: excludeAmbiguous,
            numberOfSections: numberOfSections,
            sectionLength: sectionLength,
        });
        setPassGenResult(result);
    };

    const copyPass = () => {
        var content = document.querySelector(
            '.password-generator-result input'
        );

        content.select();
        document.execCommand('copy');
        navigator.clipboard.writeText(passGenResult);

        setHasBeenCopied(true);
        setTimeout(() => {
            setHasBeenCopied(false);
        }, 3000);
    };

    const handlePasswordType = (value) => {
        console.log(value.indexOf('Sectional'));
        setTypeOfPassword(value);
        if (value.indexOf('Sectional') < 0) {
            setSectionLength(parseInt(value.replace(/\D/g, '')));
            setNumberOfSections(1);
        } else if (value === 'Sectional Plus') {
            setSectionLength(5);
            setNumberOfSections(4);
        } else {
            setSectionLength(4);
            setNumberOfSections(3);
        }
    };

    return (
        <div className="tile--section password-generator-page">
            <div
                className="tile--section--main--container"
                style={{
                    backgroundImage: 'url(' + backgroundImage + ')',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div>
                    <div className="password-generator">
                        <div className="password-generator-title">
                            Password Generator
                        </div>
                        <div className="password-generator-result">
                            <input value={passGenResult} disabled />
                        </div>
                        <div className="password-generator-options">
                            <div>
                                <Select
                                    label="Type of Password"
                                    options={[
                                        'Sectional',
                                        'Sectional Plus',
                                        '24 Characters',
                                        '16 Characters',
                                        '12 Characters',
                                        '8 Characters',
                                    ]}
                                    // valueType="index"
                                    noBlank={true}
                                    selectedOption={typeOfPassword}
                                    isChecked={includeNumbers}
                                    onChange={(x, value) =>
                                        handlePasswordType(x)
                                    }
                                />
                            </div>
                            <div>
                                <div>
                                    <CheckBoxItem
                                        label="Include Capitals"
                                        isChecked={includeCapitals}
                                        onClick={(x, value) =>
                                            setIncludeCapitals(value)
                                        }
                                    />
                                </div>
                                <div>
                                    <CheckBoxItem
                                        label="Include Numbers"
                                        isChecked={includeNumbers}
                                        onClick={(x, value) =>
                                            setIncludeNumbers(value)
                                        }
                                    />
                                </div>
                                <div>
                                    <CheckBoxItem
                                        label="Include Symbols"
                                        isChecked={includeSymbols}
                                        onClick={(x, value) =>
                                            setIncludeSymbols(value)
                                        }
                                    />
                                </div>
                                <div>
                                    <CheckBoxItem
                                        label="Exclude Ambiguous"
                                        isChecked={excludeAmbiguous}
                                        onClick={(x, value) =>
                                            setExcludeAmbiguous(value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="password-generator-controls">
                            <div className="password-generator-copy">
                                {passGenResult !== '' && (
                                    <sub onClick={() => copyPass()}>
                                        {hasBeenCopied
                                            ? 'Copied!'
                                            : 'Copy to Clipboard'}
                                    </sub>
                                )}
                            </div>
                            <div>
                                <button
                                    className="btn"
                                    onClick={() => generatePassword(true)}
                                >
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PassGenContent;
