import React, { useEffect, useState } from 'react';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';
import backgroundImage from '../../../assets/images/pages/whatwedo/business-people-staking-hands.jpg';
import AlismaToHeart from '../../../components/logo/alisma_to_heart';

const TrustLoyalty = (props) => {
    const [showMorph, setShowMorph] = useState(false);
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'light', menu: 'white' });
            setTimeout(() => {
                setShowMorph(true);
            }, 500);
        } else {
            setTimeout(() => {
                setShowMorph(false);
            }, 500);
        }
    });
    return (
        <section className="tile--section what-we-do-trust-loyalty">
            <div
                className="tile--section--main--container"
                style={{
                    backgroundImage: 'url(' + backgroundImage + ')',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className="tile--section--inner--container">
                    <div className="main--info--left tile--info--light">
                        <p>
                            Trust and loyalty in the brands we serve.{' '}
                            {/* <AlismaToHeart show={showMorph} /> */}
                        </p>
                    </div>
                    <div className="side--info--left tile--info--light">
                        <p>
                            We develop targeted strategies that help businesses
                            drive brand awareness and connect better with their
                            communities.
                        </p>
                    </div>
                </div>
                <div className="tile--section--inner--container tile--section--media--container">
                    <div>
                        <AlismaToHeart show={showMorph} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TrustLoyalty;
