import React from 'react';
import ContactTile from '../../../../contact/contact_tiles/contact_tile';
import WebsitesPortfolioIntro from '../websites_tiles/websites_portfolio_intro';
import WebsitesPortfolioTile from '../websites_tiles/websites_portfolio_tile';

import accWP from '../../../../../assets/images/pages/ourwork/websites/acc.png';
import cbgWP from '../../../../../assets/images/pages/ourwork/websites/cbg.png';
import jgwWP from '../../../../../assets/images/pages/ourwork/websites/jgw.png';
import ldmWP from '../../../../../assets/images/pages/ourwork/websites/ldm.png';
import ldgWP from '../../../../../assets/images/pages/ourwork/websites/ldg.png';
import mntpWP from '../../../../../assets/images/pages/ourwork/websites/tpmn.png';
import inocWP from '../../../../../assets/images/pages/ourwork/websites/inoc.png';
import cgdiWP from '../../../../../assets/images/pages/ourwork/websites/cgdi.png';
import adlWP from '../../../../../assets/images/pages/ourwork/websites/adl.png';
import crWP from '../../../../../assets/images/pages/ourwork/websites/cr.png';
import hhWP from '../../../../../assets/images/pages/ourwork/websites/hh.png';

// Default Layout
// caption: --Main info
// text: --Alt Info
// button: --Button text
// path: --React Router Path
// type: --Tile Type
// background: --SVG Background pulled via import
// background-image : --Image address
// component: <Component />

const websitesPortfolioInfo = [
    {
        type: 'component',
        component: <WebsitesPortfolioIntro />,
    },
    {
        type: 'component',
        component: (
            <WebsitesPortfolioTile
                mainInfo="Annandale Chamber of Commerce"
                sideInfo="Rebuilt from the ground up, this custom website
                          helps keep local businesses, along with the
                          community, informed with upcoming events and allows them
                          to interact with chamber directly."
                websitePic={accWP}
            />
        ),
    },
    {
        type: 'component',
        component: (
            <WebsitesPortfolioTile
                mainInfo="Clays Bar & Grill"
                sideInfo="Rebranding as a local hotspot for live
                            entertainment, Clays Bar & Grill asked us to rebuild
                            their site to allow their guests to stay up to date
                            with upcoming events and activities."
                websitePic={cbgWP}
            />
        ),
    },
    {
        type: 'component',
        component: (
            <WebsitesPortfolioTile
                mainInfo="JG Woodworking"
                sideInfo="Wanting to rebrand and start fresh with a new
                            website, JG Woodworking contacted us to build this
                            page to accomodate for their growing business."
                websitePic={jgwWP}
            />
        ),
    },
    {
        type: 'component',
        component: (
            <WebsitesPortfolioTile
                mainInfo="Lynn Dent Modeling"
                sideInfo="When this model decided to take the leap, they hired
                            us to build a single page website that would allow
                            potential clients and easy way to see previous work
                            and allow them to contact her right from the page."
                websitePic={ldmWP}
            />
        ),
    },
    {
        type: 'component',
        component: (
            <WebsitesPortfolioTile
                mainInfo="LDG Drywall"
                sideInfo="We were hired us to rebuild thier website and bring
                            it up to date with the latest styles. Stepping
                            forward from a highly outdated site allows them to
                            keep their business at the front of the game."
                websitePic={ldgWP}
            />
        ),
    },
    {
        type: 'component',
        component: (
            <WebsitesPortfolioTile
                mainInfo="MN Telecom Pioneers - Ch 18"
                sideInfo="Their new page is a great way for their communities
                            to stay up to date, get involved with possible
                            volunteer opprotunities."
                websitePic={mntpWP}
            />
        ),
    },
    {
        type: 'component',
        component: (
            <WebsitesPortfolioTile
                mainInfo="In Need of Capital"
                sideInfo="We've partnered with them to help this non-profit
                            get the reach they need to be able to fulfill their
                            dream of helping buisinesses start-up or make it
                            through tough times."
                websitePic={inocWP}
            />
        ),
    },
    {
        type: 'component',
        component: (
            <WebsitesPortfolioTile
                mainInfo="Clays Galxay Drive In"
                sideInfo="We gave their website a fun user experience with a
                            beautifully responsive and adaptive design that
                            incorporated some the old style concepts into their
                            existing brand footprint."
                websitePic={cgdiWP}
            />
        ),
    },
    {
        type: 'component',
        component: (
            <WebsitesPortfolioTile
                mainInfo="Anchor Dock & Lift"
                sideInfo="We gave this website an elegant look with seamless
                            animations and custom built graphics that provide a
                            relaxing look and feel to represent their lakeside
                            products and services."
                websitePic={adlWP}
            />
        ),
    },
    {
        type: 'component',
        component: (
            <WebsitesPortfolioTile
                mainInfo="Clays Restaurant"
                sideInfo="With their core values on providing family fun, we
                            gave their website an easy to use beautiful design
                            with custom animations that was created to captivate
                            their ideal target audience."
                websitePic={crWP}
                // caseLink="/our-work/claysrestaurant"
            />
        ),
    },
    {
        type: 'component',
        component: (
            <WebsitesPortfolioTile
                mainInfo="HoofHaulers"
                sideInfo="This local horse trailer company was in search of a
                            new and up to date website that would make their
                            products and services easy to explore. We gave this
                            website a patriotic look with custom graphic designs
                            and interactive components."
                websitePic={hhWP}
                // caseLink="/our-work/hoofhaulers"
            />
        ),
    },
    {
        type: 'component',
        component: <ContactTile />,
    },
];

export default websitesPortfolioInfo;
