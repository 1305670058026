import React from 'react';
import mandyHead from '../../../assets/images/pages/contact/mandy_head_1.jpg';
import deaconHead from '../../../assets/images/pages/contact/deacon_head_1.jpg';

const ContactTileHeadshots = (props) => {
    return (
        <div className="contact-tile-contact-headshots">
            <div>
                <div className="contact-tile-round-image">
                    <img src={mandyHead} alt="" />
                </div>
                <p>
                    Amanda Amundson
                    <br />
                    <span>Sales | Marketing</span>
                </p>
            </div>
            <div>
                <div className="contact-tile-round-image">
                    <img src={deaconHead} alt="" />
                </div>
                <p>
                    Deacon Amundson
                    <br />
                    <span>Designer | Developer</span>
                </p>
            </div>
        </div>
    );
};

export default ContactTileHeadshots;
