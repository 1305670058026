// import caseFooterInfo from './caseFooterInfo';

// **Default Layout**
// className: --Class given to specific tiles
// caption: --Main info
// text: --Alt Info
// fatText: --Fatter text than alt text (white also)
// button: --Button text
// path: --React Router Path
// clientBannerName: --Name of Client fot side banner - FIRST ITEM ONLY!
// clientName: --Name of Client fot identifying and alt tags
// clientLogo: --Name to image file containing logo
// imageLeft: --image stuck to left side of screen
// imageCenter: --slightly larger image in center of screen
// imageRight: --image stuck to right side of screen
// type: --Tile Type
// background: --SVG Background pulled via import
// backgroundImage: --Raster Background pulled via IMG tag
// backgroundImageColor: --Raster Background pulled via IMG tag in full color

const workInfo = [
    {
        caption: 'Brand new business. Fresh new concept.',
        text: '',
        backgroundImageColor:
            'cases/clays/backgrounds/cr_people_drinks_bar.jpg',
        clientBannerName: 'Clays Restaurant',
        type: 'case',
    },
    {
        className: 'about',
        caption: '',
        text: 'Family friendly and inviting, high quality imagery and custom graphics that could help build this new brand’s reputation.',
        backgroundImage: 'cases/clays/backgrounds/cr_people_restaurant.jpg',
        clientName: 'Clays Restaurant',
        imageTop: 'client_logos/clayslogo_no_outline.svg',
        imageLeft: 'cases/clays/devices/cr_homepage_phone.png',
        type: 'case',
    },
    {
        className: 'menu',
        caption: 'Responsive Online Menu',
        text: 'Introducing a 100% responsive menu, including a menu category selector to allow visitors to choose what they want to see.',
        imageCenter: 'cases/clays/devices/devices_white_back.png',
        clientName: 'Clays Restaurant',
        type: 'case',
    },
    {
        className: 'app',
        caption: '',
        text: 'An easy to use online application giving a wider choice of potential employees really lets them choose the cream of the crop.',
        imageLeft: 'cases/clays/devices/cr_careers_phone.png',
        imageRight: 'cases/clays/devices/cr_application_tablet.png',
        clientName: 'Clays Restaurant',
        type: 'case',
    },
    {
        className: 'card',
        caption:
            "\"We want it to say 'sports' but never actually say 'sports'... \"",
        text: 'And we delivered no less.',
        imageLeft: 'cases/clays/windows/cr_mcards_window.png',
        imageRight: 'cases/clays/other/cr_card_james.png',
        clientName: 'Clays Restaurant',
        type: 'case',
    },
    {
        className: 'result',
        caption: 'The Result',
        text: 'With over 1.2k visitors a month, Clays Restaurant is definately open for business. Choosing monthly site updates and ongoing custom graphics has secured their place in their community.',
        imageLeft: 'cases/clays/devices/devices_three_phones.png',
        imageRight: 'cases/clays/devices/cr_graph_mbp.png',
        clientName: 'Clays Restaurant',
        type: 'case',
    },
];
// const len = Object.keys(workInfo).length - 1;
// workInfo[len].backgroundImage = '.' + workInfo[len].backgroundImage;

export default workInfo;
