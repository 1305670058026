import React from 'react';
import ContactTile from '../../contact/contact_tiles/contact_tile';
import AboutIntro from '../about_tiles/about_intro';
import WhatWeWere from '../about_tiles/whatwewere';
import OurMission from '../about_tiles/our_mission';
import OurVision from '../about_tiles/our_vision';
import OurValues from '../about_tiles/our_values';
import BioDesigner from '../about_tiles/bio_designer';
import BioSales from '../about_tiles/bio_sales';

// Default Layout
// caption: --Main info
// text: --Alt Info
// button: --Button text
// path: --React Router Path
// type: --Tile Type
// background: --SVG Background pulled via import
// background-image : --Image address
// component: <Component />

const aboutInfo = [
    {
        type: 'component',
        component: <AboutIntro />,
    },
    {
        type: 'component',
        component: <WhatWeWere />,
    },
    {
        type: 'component',
        component: <OurValues />,
    },
    {
        type: 'component',
        component: <OurVision />,
    },
    {
        type: 'component',
        component: <OurMission />,
    },
    // {
    //     type: 'component',
    //     component: <BioSales />,
    // },
    // {
    //     type: 'component',
    //     component: <BioDesigner />,
    // },
    {
        type: 'component',
        component: <ContactTile />,
    },
];

export default aboutInfo;
