import React, { useEffect } from 'react';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';
import backgroundImage from '../../../assets/images/pages/about/design-work-2021-09-02-10-58-44-utc.jpg';

const AboutIntro = (props) => {
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'light', menu: 'white' });
        }
    });
    return (
        <section className="tile--section about-intro">
            <div
                className="tile--section--main--container"
                style={{
                    backgroundImage: 'url(' + backgroundImage + ')',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className="tile--section--inner--container">
                    <div className="main--info--left tile--info--light">
                        <p>
                            We create a complete solution that is tailored to
                            fit the individual needs of each business we serve.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutIntro;
