import React, { useEffect } from 'react';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';

const OurMission = (props) => {
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'dark', menu: 'light' });
            if (window.innerWidth < 400) {
                toggleHeaderTheme({ menu: 'black' });
            }
        }
    });
    return (
        <section className="tile--section about-our-mission">
            <div className="tile--section--main--container">
                <div className="tile--section--inner--container">
                    <div className="main--info--left tile--info--dark">
                        <p>Our Mission</p>
                    </div>
                    <div className="side--info--left tile--info--dark">
                        <p>
                            We strive to inspire loyalty and trust in the brands
                            we serve by creating stunning designs and custom
                            experiences that get your business the attention it
                            deserves.
                        </p>
                    </div>
                </div>
                <div className="tile--section--inner--container tile--section--media--container">
                    <div>
                        {/* <img
                            src={_pathImg + 'pages/home/photo_group_8.png'}
                            alt=""
                        /> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurMission;
