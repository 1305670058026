import React from 'react';
import FirstContact from '../home_tiles/first_contact';
import AboutPreview from '../home_tiles/about_preview';
import ContactTile from '../../contact/contact_tiles/contact_tile';
import ProcessPreview from '../home_tiles/process_preview';
import OurCommitment from '../home_tiles/our_commitment';
import WhatWeDoPreview from '../home_tiles/whatwedo_preview';

// Default Layout
// caption: --Main info
// text: --Alt Info
// button: --Button text
// path: --React Router Path
// type: --Tile Type
// background: --SVG Background pulled via import
// background-image : --Image address
// component: <Component />

const homeInfo = [
    {
        type: 'component',
        component: <FirstContact />,
    },
    {
        type: 'component',
        component: <AboutPreview />,
    },
    {
        type: 'component',
        component: <WhatWeDoPreview />,
    },
    {
        type: 'component',
        component: <ProcessPreview />,
    },
    {
        type: 'component',
        component: <OurCommitment />,
    },
    {
        type: 'component',
        component: <ContactTile />,
    },
];

export default homeInfo;
