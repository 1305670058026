import React from 'react';

const ContactTileInfo = (props) => {
    return (
        <div className="contact-tile-contact-info">
            <div>
                <ul>
                    <li>Email: hello@alisma.io</li>
                    <li>Phone: (844) 830-0115</li>
                    <li>Phone: (763) 232-4889</li>
                    <li>Clearwater, MN 55320</li>
                </ul>
            </div>
        </div>
    );
};

export default ContactTileInfo;
