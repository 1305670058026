import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Content from '../../components/content/content';
import aboutInfo from './about_info/about_info';

class About extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>A L I S M A - About</title>
                    <meta
                        name="description"
                        content="Our commitment to providing responsive and reliable services gives our clients a foundation they can depend on for long-term success"
                    />
                </Helmet>
                <Content info={aboutInfo} />
            </React.Fragment>
        );
    }
}

export default About;
