const passGenerator = ({
    includeCapitals: caps,
    includeNumbers: numbers,
    includeSymbols: symbols,
    excludeAmbiguous: ambiguous,
    numberOfSections: sections,
    sectionLength: sectLength,
}) => {
    var result = '';

    var characters = 'abcdefghijklmnopqrstuvwxyz';

    if (caps) {
        characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }

    if (numbers) {
        characters += '0123456789';
    }

    if (symbols) {
        characters += '!@#$%^&?+=*';
    }

    if (ambiguous) {
        characters = characters.replace(/l|1|0|O|I|C|Q/gi, '');
    }

    for (let i = 0; i < sections; i++) {
        for (let j = 0; j < sectLength; j++) {
            result += characters.charAt(
                Math.floor(Math.random() * characters.length)
            );
        }
        if (i + 1 < sections) {
            result += '-';
        }
    }
    return result;
};
export default passGenerator;
