import React, { useEffect, useState } from 'react';
import { _i, _isMobile } from '../functions/alisma_library_react';

const ScrollWindowIndicator = (props) => {
    const [showIndicator, setShowIndicator] = useState(true);
    useEffect(() => {
        if (!props.show) {
            setShowIndicator(false);
        }
    });
    if (!showIndicator || _isMobile.any()) return false;
    return <div className="scroll-window-indicator" />;
};

export default ScrollWindowIndicator;
