import React from 'react';
import { NavLink } from 'react-router-dom';

export default class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loaded: false,
        };
        this.state = this.initialState;
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        this.setState({
            loaded: true,
        });
    }
    handleClick(e) {}
    render() {
        const { loaded } = this.state;
        // const { item } = this.props;

        if (!loaded) {
            return <span>...</span>;
        } else {
            return (
                <div
                    className={this.props.className}
                    onClick={this.handleClick}
                >
                    <div className="menu-body">
                        <NavLink to="/what-we-do" title="What We Do">
                            What We Do
                        </NavLink>
                        <br />
                        <NavLink to="/our-work" title="Our Work">
                            Our Work
                        </NavLink>

                        <br />
                        <NavLink to="/about" title="About Us">
                            About Us
                        </NavLink>
                        <br />
                        <NavLink to="/contact" title="Contact Us">
                            Contact Us
                        </NavLink>
                        <br />
                        <br />

                        {/* <p>Be sure and follow us to stay up to date!</p> */}
                        <a
                            className="menu-social"
                            href="https://www.linkedin.com/in/deacon.amundson"
                            target="__blank"
                            rel="noopener noreferrer nofollow"
                            title="LinkedIn"
                        >
                            LinkedIn
                        </a>
                        <br />
                        <a
                            className="menu-social"
                            href="https://dribbble.com/deaconamundson"
                            target="__blank"
                            rel="noopener noreferrer nofollow"
                            title="Dribble"
                        >
                            Dribble
                        </a>
                        <br />
                        <a
                            className="menu-social"
                            href="https://www.facebook.com/polarcode.net/"
                            target="__blank"
                            rel="noopener noreferrer nofollow"
                            title="Facebook"
                        >
                            Facebook
                        </a>
                        <br />
                        <p>Our Partners</p>
                        <a
                            className="menu-assoc"
                            href="https://deaconamundson.com"
                            target="__blank"
                            rel="noopener noreferrer nofollow"
                            title="Deacon Amundson Photography"
                        >
                            Deacon Amundson Photography
                        </a>
                        <br />
                        <a
                            className="menu-assoc"
                            href="https://inneedofcapital.com"
                            target="__blank"
                            rel="noopener noreferrer nofollow"
                            title="In Need of Capital"
                        >
                            In Need of Capital
                        </a>
                        {/* <p>
                            <span>Clearwater, MN</span>
                        </p> */}
                    </div>
                </div>
            );
        }
    }
}
