import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';
import photoGroup from '../../../assets/images/pages/whatwedo/photo_group_6.png';

const PowerfulMarketing = (props) => {
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'dark' });
        }
        if (props.inView && window.innerWidth < 400) {
            toggleHeaderTheme({ menu: 'black' });
        } else if (props.inView && window.innerWidth > 400) {
            toggleHeaderTheme({ menu: 'white' });
        }
    });
    return (
        <section className="tile--section what-we-do-powerful-message">
            <div className="tile--section--main--container">
                <div className="tile--section--inner--container">
                    <div className="main--info--left tile--info--dark">
                        <p>Powerful marketing that encompasses your message</p>
                    </div>
                    <div className="side--info--left tile--info--dark">
                        <p>
                            We have a timeless way of helping brands develop a
                            cohesive image that sets them apart from
                            competitors.
                        </p>
                    </div>
                </div>
                <div className="tile--section--inner--container tile--section--media--container">
                    <div>
                        <img src={photoGroup} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PowerfulMarketing;
