import React from 'react';
export const alismaTitle = (
    <svg id="Layer_2" viewBox="0 0 896.11 147.44">
        <g id="Layer_1-2">
            <g id="alisma-logo">
                <g id="blob">
                    <path
                        className="cls-1"
                        d="M117.24,146.44c-6.04,0-11.85-1.33-17.25-3.97l-.06-.03-.06-.04c-6.22-4.24-13.49-6.47-21.04-6.47s-14.82,2.24-21.04,6.47l-.06,.04-.06,.03c-5.4,2.63-11.2,3.97-17.25,3.97-21.73,0-39.41-17.68-39.41-39.41,0-13.33,6.59-25.54,17.68-32.88l-.1-.18,.92-.49c12.29-6.51,19.92-19.19,19.92-33.08,0-21.73,17.68-39.41,39.41-39.41s39.41,17.68,39.41,39.41c0,13.89,7.63,26.57,19.92,33.08l.92,.49-.1,.18c11.09,7.33,17.68,19.55,17.68,32.88,0,21.73-17.68,39.41-39.41,39.41Z"
                    />
                </g>
                <g id="title">
                    <polygon points="892.05 140.61 896.11 140.61 843.63 13.49 791.15 140.61 795.21 140.61 843.63 23.31 892.05 140.61" />
                    <polygon points="747.3 140.61 747.3 13.49 739.78 13.49 703.29 90.84 666.8 13.49 659.27 13.49 659.27 140.61 665.72 140.61 665.72 26.46 703.29 105.97 740.85 26.46 740.85 140.61 747.3 140.61" />
                    <path d="M596.01,91.65c-3.55-5.29-7.87-9.36-12.84-12.11-5.5-3.03-11.08-5.93-16.59-8.61-6-2.92-11.2-6.6-15.46-10.94-4.72-4.8-7.12-10.72-7.12-17.57,0-6.33,1.58-11.35,4.69-14.92,3.31-3.79,8.63-5.63,16.29-5.63,6.13,0,11.24,1.96,15.2,5.82,3.56,3.49,5.67,7.96,6.29,13.32h10.4c-.15-2.04-.64-4.3-1.45-6.73-1.02-3.06-2.7-6.34-4.99-9.74-2.08-3.09-5.3-5.72-9.56-7.82-4.34-2.14-9.54-3.22-15.46-3.22-6.58,0-12.19,.94-16.68,2.8-4.2,1.74-7.29,4.07-9.18,6.93-2.11,3.2-3.63,6.48-4.5,9.75-.9,3.36-1.36,7.04-1.36,10.96,0,7.21,1.68,13.11,5.15,18.01,3.56,5.04,7.9,9,12.9,11.75,5.47,3.02,11.07,5.96,16.63,8.74,5.97,2.99,11.16,6.7,15.42,11.03,4.73,4.82,7.12,10.73,7.12,17.57,0,1.67-.13,3.26-.39,4.71-.27,1.56-.94,3.55-2.1,6.28-1.39,3.28-3.92,5.89-7.51,7.78-3.32,1.75-7.45,2.63-12.28,2.63-8.95,0-15.08-2.01-18.74-6.15-3.24-3.66-5.2-8.46-5.81-14.29h-10.28c.99,9.77,4.51,16.72,10.73,21.22,6.76,4.89,14.94,7.38,24.32,7.38s17.24-2.47,23.48-7.55c5.98-4.87,8.88-12.14,8.88-22.23,0-7.57-1.75-14.02-5.2-19.17Z" />
                    <rect x="459.15" y="13.49" width="14.33" height="127.12" />
                    <polygon points="347.49 13.49 347.49 140.61 402.92 140.61 402.92 126.56 363.63 126.56 363.63 13.49 347.49 13.49" />
                    <polygon points="286.35 140.61 252.26 58.04 218.18 140.61 199.8 140.59 252.26 13.49 304.73 140.59 286.35 140.61" />
                </g>
            </g>
        </g>
    </svg>
);

export default alismaTitle;
