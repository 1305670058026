import React, { useState, useEffect } from 'react';
import './_checkitem.css';
import CheckBox from './CheckBox';

const CheckItem = (props) => {
    const [isChecked, setIsChecked] = useState('');
    useEffect(() => {
        setIsChecked(props.isChecked);
    }, [props.isChecked]);
    // const style = {};

    // if (props.bgColor) {
    //     props.isChecked && (style.background = props.bgColor);
    // }
    // if (props.checkColor) {
    //     props.isChecked && (style.borderColor = props.checkColor);
    // }

    return (
        <div className="check-item">
            <div
                className="check-item-content"
                onClick={() => {
                    setIsChecked(!isChecked);
                    props.onClick(props.label, !isChecked);
                }}
            >
                <CheckBox
                    bgColor={props.bgColor}
                    checkColor={props.checkColor}
                    isChecked={isChecked}
                />
                <div>{props.label}</div>
            </div>
        </div>
    );
};

// !Example
// <CheckBoxItem
//    bgColor="lightblue"
//    checkColor="blue"
//    label="Check Box Item"
//    isChecked={checkBoxItemResponse === 'Check Box Item'}
//    onClick={(value) => setCheckBoxItemResponse(value)}
//  />
export default CheckItem;
