import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { _q } from '../../../functions/alisma_library_react';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';
import aceLogo from '../../../assets/images/pages/ourwork/logos/ace_logo_gs.svg';
import ashLogo from '../../../assets/images/pages/ourwork/logos/ash_logo_210207_1.5_final.svg';
import crLogo from '../../../assets/images/pages/ourwork/logos/clays_logo_gs.svg';
import cgdiLogo from '../../../assets/images/pages/ourwork/logos/galaxy_logo_200316_gs.svg';
import hhmLogo from '../../../assets/images/pages/ourwork/logos/hhm_190305_gs.svg';
import inocLogo from '../../../assets/images/pages/ourwork/logos/inoc_logo_210508_gs.svg';
import jgwLogo from '../../../assets/images/pages/ourwork/logos/jgw_logo_210525_gs.svg';
import dapLogo from '../../../assets/images/pages/ourwork/logos/dap_title_white_220903_1.1.svg';
import graphicsVideo from '../../../assets/videos/ourwork/graphic_design_video.mp4';

const OurWorkGraphics = (props) => {
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'white', menu: 'light' });
            if (_q('.ourwork-graphics-video').paused) {
                _q('.ourwork-graphics-video').play();
            }
        } else {
            if (!_q('.ourwork-graphics-video').paused) {
                _q('.ourwork-graphics-video').pause();
            }
        }
    });
    return (
        <section className="tile--section our-work-categories">
            <div className="tile--section--main--container">
                <div className="tile--section--inner--container tile--section--media--container">
                    <div className="main--info--right tile--info--light">
                        <div className="our-work-categories-logos">
                            <div>
                                <img src={aceLogo} alt="" />
                            </div>
                            <div>
                                <img src={crLogo} alt="" />
                            </div>
                            <div>
                                <img src={cgdiLogo} alt="" />
                            </div>
                            <div>
                                <img src={hhmLogo} alt="" />
                            </div>
                            <div>
                                <img src={inocLogo} alt="" />
                            </div>
                            <div>
                                <img src={jgwLogo} alt="" />
                            </div>
                            <div>
                                <img src={dapLogo} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tile--section--inner--container">
                    <video
                        className="ourwork-graphics-video"
                        poster=""
                        loop
                        muted
                        preload="metadata"
                        playsinline=""
                    >
                        <source src={graphicsVideo} />
                    </video>
                    <div className="main--info--center tile--info--light">
                        <p>Graphic Design</p>
                        <NavLink
                            to="/our-work/graphics"
                            title="View Graphic Design Examples"
                        >
                            <button className="button">View</button>
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurWorkGraphics;
