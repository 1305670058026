import React from 'react';
import { _pathBase } from '../../../functions/controls/app_paths';
import { _year } from '../../../functions/alisma_library_react';

const ContactTileFooter = (props) => {
    return (
        <footer>
            <div className="footer-privacy-info">
                <a
                    href={_pathBase + 'privacy.html'}
                    title="Privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    privacy | terms
                </a>
            </div>
            <div className="footer-copyright-info">
                All Rights Reserved.
                <br />
                &copy;{_year()} ALISMA LLC
            </div>
        </footer>
    );
};

export default ContactTileFooter;
