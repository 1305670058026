import React from 'react';
import Menu from './menu';
export default class MenuButton extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loaded: false,
            isOpen: false,
            menuIsShowing: false,
            contactIsShowing: false,
        };
        this.state = this.initialState;
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        this.setState({
            loaded: true,
        });
    }
    componentWillReceiveProps(props) {
        this.setState({ isOpen: props.open });
        if (!props.open) {
            setTimeout(() => {
                this.setState({ menuIsShowing: false });
            }, 500);
        }
    }
    componentWillUnmount() {
        this.setState({
            loaded: false,
        });
    }
    handleClick(e) {
        this.setState({ isOpen: !this.state.isOpen });
        !this.state.isOpen
            ? this.setState({ menuIsShowing: true })
            : setTimeout(() => {
                  this.setState({ menuIsShowing: false });
              }, 500);
    }
    render() {
        const { loaded, isOpen, menuIsShowing, contactIsShowing } = this.state;
        if (!loaded) {
            return <span>...</span>;
        } else {
            return (
                <div
                    className={isOpen ? 'menu-button open' : 'menu-button'}
                    onClick={this.handleClick}
                >
                    <div className="menu-bars menu-bars-light">
                        <div className="bar" />
                        <div className="bar" />
                        <div className="bar" />
                    </div>
                    <div
                        className={isOpen ? 'menu-mask open' : 'menu-mask'}
                    ></div>
                    {menuIsShowing && (
                        <Menu className={isOpen ? 'menu' : 'menu fade'} />
                    )}
                </div>
            );
        }
    }
}
