import React, { Component } from 'react';
import Content from '../../content/content';
import claysinfo from '../../content/infos/case_infos/claysinfo';

class Clays extends Component {
    render() {
        return (
            <React.Fragment>
                <Content info={claysinfo} className="cs-clays" />
            </React.Fragment>
        );
    }
}

export default Clays;
