import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';
// import photoGroup from '../../../assets/images/pages/home/photo_group_8.png';
import whatWeDoPrevThumb from '../../../assets/videos/home/whatwedo_thumb.png';
import whatWeDoPrevVideo from '../../../assets/videos/home/whatwedo_preview.mp4';
import { _q } from '../../../functions/alisma_library_react';

const WhatWeDoPreview = (props) => {
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'dark', menu: 'white' });
            if (_q('.whatwedo-preview-video').paused) {
                _q('.whatwedo-preview-video').play();
            }
        } else {
            if (!_q('.whatwedo-preview-video').paused) {
                _q('.whatwedo-preview-video').pause();
            }
        }
        if (props.inView && window.innerWidth < 400) {
            toggleHeaderTheme({ menu: 'black' });
        }
    });
    return (
        <section className="tile--section home-what-we-do-preview">
            <div className="tile--section--main--container">
                <div className="tile--section--inner--container">
                    <div className="main--info--left tile--info--dark">
                        <p>Websites, Graphics, Digital Marketing.</p>
                    </div>
                    <div className="side--info--left tile--info--dark">
                        <p>
                            We believe in the power of a strong identity and a
                            stunning online presence.
                        </p>
                        <NavLink to="/about" title="Learn More">
                            <button className="button">Learn More</button>
                        </NavLink>
                    </div>
                </div>
                <div className="tile--section--inner--container tile--section--media--container">
                    {/* <div>
                        <img src={photoGroup} alt="" />
                    </div> */}

                    <video
                        className="whatwedo-preview-video"
                        poster={whatWeDoPrevThumb}
                        loop
                        muted
                        preload="metadata"
                        playsInline
                    >
                        <source src={whatWeDoPrevVideo} type="video/mp4" />
                    </video>
                </div>
            </div>
        </section>
    );
};

export default WhatWeDoPreview;
