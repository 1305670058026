import React from 'react';
import { NavLink } from 'react-router-dom';

export default class ClientTile extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loaded: false
        };
        this.state = this.initialState;
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        this.setState({
            loaded: true,
            item: this.props.item
        });
    }
    handleClick(e) {}
    render() {
        const { loaded, item } = this.state;

        if (!loaded) {
            return <span>...</span>;
        }
        const style = { backgroundColor: item.background };
        return (
            <div className={'tile ' + item.type} style={style}>
                <NavLink exact to={'/' + item.path}>
                    <img
                        className="client-logo"
                        width="30%"
                        src={'./img/client_logos/' + item.clientLogo}
                        alt={item.clientName}
                        title={item.clientName + ' | Case Study'}
                    />
                </NavLink>
            </div>
        );
    }
}
