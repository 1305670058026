import React, { useEffect, useState } from 'react';
import AlismaFromPCD from '../../../components/logo/alisma_from_pcd';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';

const WhatWeWere = (props) => {
    const [showMorph, setShowMorph] = useState(false);
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'white', menu: 'black' });
            setTimeout(() => {
                setShowMorph(true);
            }, 1000);
        } else {
            setTimeout(() => {
                setShowMorph(false);
            }, 500);
        }
        if (props.inView && window.innerWidth < 400) {
            toggleHeaderTheme({ menu: 'white' });
        }
    });
    return (
        <section className="tile--section about-whatwewere">
            <div className="tile--section--main--container">
                <div className="tile--section--inner--container tile--section--media--container">
                    <div>
                        <div
                            className={
                                'about-whatwewere-icon' +
                                (props.inView ? ' in' : '')
                            }
                        >
                            <AlismaFromPCD show={showMorph} />
                        </div>
                    </div>
                </div>
                <div className="tile--section--inner--container">
                    <div className="main--info--right tile--info--dark">
                        <p>Where we've been, and where we're going.</p>
                    </div>
                    <div className="side--info--right tile--info--dark">
                        <p>
                            Starting out as Polarcode in 2014 and offering only
                            websites, we've since grown our service offerings
                            and have rebranded to match.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhatWeWere;
