import React, { useEffect } from 'react';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';
import backgroundImage from '../../../assets/images/pages/ourwork/blank_chalkboard.jpeg';

const OurWorkIntro = (props) => {
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'light', menu: 'light' });
        }
    });
    return (
        <section className="tile--section our-work-intro">
            <div
                className="tile--section--main--container"
                style={{
                    backgroundImage: 'url(' + backgroundImage + ')',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className="tile--section--inner--container">
                    <div className="main--info--left tile--info--light">
                        <p>Imagine the possibilities.</p>
                    </div>
                    <div className="side--info--left tile--info--light">
                        <p>
                            What if you had the power to increase brand
                            recognition, bring in new customers, easily reach
                            intended audiences, all while making your products
                            and services easy to explore?
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurWorkIntro;
