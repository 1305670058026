import React, { useState, useEffect } from 'react';
import './_checkbox.css';

const CheckBox = (props) => {
    const [isChecked, setIsChecked] = useState('');
    useEffect(() => {
        setIsChecked(props.isChecked);
    }, [props.isChecked]);
    const style = {};

    if (props.bgColor) {
        (props.isChecked || isChecked) && (style.background = props.bgColor);
    }
    if (props.checkColor) {
        (props.isChecked || isChecked) &&
            (style.borderColor = props.checkColor);
    }

    return (
        <div
            id={props.id}
            style={style}
            className={'check-box' + (isChecked ? ' checked' : '')}
            onClick={() => {
                setIsChecked(!isChecked);
                props.onClick && props.onClick(!isChecked);
            }}
        />
    );
};

// !Example
// <CheckBox
//     bgColor={props.bgColor}
//     checkColor={props.checkColor}
//     isChecked={isChecked}
//     onClick={() => props.onClick(!isChecked);}
// />;
export default CheckBox;
