export const _pathBase =
    `${process.env.NODE_ENV}` === 'development'
        ? `${process.env.REACT_APP_BASE_DEV}`
        : `${process.env.REACT_APP_BASE}`;

export const _pathApi =
    `${process.env.NODE_ENV}` === 'development'
        ? `${process.env.REACT_APP_API_DEV}`
        : `${process.env.REACT_APP_API}`;

export const _pathAdminApi =
    `${process.env.NODE_ENV}` === 'development'
        ? `${process.env.REACT_APP_ADMIN_API_DEV}`
        : `${process.env.REACT_APP_ADMIN_API}`;

export const _pathImg =
    `${process.env.NODE_ENV}` === 'development'
        ? `${process.env.REACT_APP_IMG_DEV}`
        : `${process.env.REACT_APP_IMG}`;

export const _pathAdminImg =
    `${process.env.NODE_ENV}` === 'development'
        ? `${process.env.REACT_APP_ADMIN_IMG_DEV}`
        : `${process.env.REACT_APP_ADMIN_IMG}`;

export const _pathNested =
    `${process.env.NODE_ENV}` === 'development' ? '' : '..';
