import React from 'react';
import DayInput from './inputs/day_input';
import TotalInput from './inputs/total_input';

export default class TimeConverter extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            index: [
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
                'Sunday',
            ],
            days: [
                { day: 'Monday', value: 0, total: 0 },
                { day: 'Tuesday', value: 0, total: 0 },
                { day: 'Wednesday', value: 0, total: 0 },
                { day: 'Thursday', value: 0, total: 0 },
                { day: 'Friday', value: 0, total: 0 },
                { day: 'Saturday', value: 0, total: 0 },
                { day: 'Sunday', value: 0, total: 0 },
            ],
            weekTotal: '0 hrs',
        };
        this.state = this.initialState;
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }
    handleKeyPress(value, day) {
        const hasPartial = value.indexOf('.');

        const wholeHours = value.slice(
            0,
            hasPartial < 0 ? 99 : value.indexOf('.')
        );
        const partialHours =
            hasPartial < 0 ? 0 : value.slice(value.indexOf('.'), 99) * 60;

        const total = wholeHours + 'hrs ' + partialHours.toFixed(0) + 'min';

        let days = this.state.days;
        days[this.state.index.indexOf(day)] = {
            day: day,
            value: value,
            total: total,
        };
        this.setState({
            days: days,
        });

        setTimeout(() => {
            let weekTotal = 0;
            for (let i = 0; i < this.state.index.length; i++) {
                weekTotal = weekTotal + Number(this.state.days[i].value);
            }
            this.setState({
                weekTotal: weekTotal,
            });
        }, 10);
    }
    compileTotals() {
        let total = 0;
        for (let i = 0; i < this.state.index; i++) {
            total = total + Number(this.state.days[i].value);
        }
        this.setState({
            weekTotal: total,
        });
        console.log(total);
    }
    render() {
        const { days, weekTotal } = this.state;
        const style = {
            position: 'relative',
            width: 300,
            margin: 'auto',
            marginTop: 60,
            fontFamily: 'Verdana',
            border: '1px solid #eaeaea',
            borderRadius: '8px',
            textAlign: 'center',
            padding: 8,
            backgroundColor: '#000',
            color: '#fff',
        };

        return (
            <div id="time-converter" style={style}>
                {/* <h3>Time Converter</h3> */}

                {days.map((day) => (
                    <DayInput
                        key={day.day}
                        day={day.day}
                        onChange={this.handleKeyPress}
                        total={day.total}
                    />
                ))}

                <TotalInput weekTotal={weekTotal} />
            </div>
        );
    }
}
