import React, { useEffect, useState } from 'react';
import { _year } from '../../../functions/alisma_library_react';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';
// import ContactTileFooter from './contact_tile_footer';
// import ContactTileHeadshots from './contact_tile_headshots';
// import ContactTileInfo from './contact_tile_info';
import ContactForm from './form/ContactForm';
import headerImage from '../../../assets/images/pages/giveaway/alisma_flyer_giveaway2304_header_230414_1.0@2x.png';

const ContactTile = (props) => {
    const [splitContactTile, setSplitContactTile] = useState(
        window.innerWidth < 1000
    );
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'white' });
            toggleHeaderTheme({ menu: 'dark' });
            if (window.innerWidth < 1000) {
                toggleHeaderTheme({ menu: 'dark' });
                toggleHeaderTheme({ logo: 'dark' });
            }
        }

        const adjustSize = () => {
            if (window.innerWidth < 1000) {
                setSplitContactTile(true);

                if (props.inView) {
                    toggleHeaderTheme({ menu: 'dark' });
                }
            } else {
                setSplitContactTile(false);
                if (props.inView) {
                    toggleHeaderTheme({ menu: 'light' });
                }
            }
        };

        window.addEventListener('resize', adjustSize);

        return function cleanUp() {
            window.removeEventListener('resize', adjustSize);
        };
    });
    return (
        <section className="tile--section contact-tile giveaway">
            <div className="tile--section--main--container">
                <aside
                    className={
                        'tile--section--inner--container tile--section--media--container' +
                        (!splitContactTile ? '' : ' tile--section')
                    }
                >
                    <div>
                        <img
                            src={headerImage}
                            alt="Alisma Giveaway!"
                            style={{ height: '30vh' }}
                        />

                        {!splitContactTile ? (
                            ''
                        ) : (
                            <div
                                style={{
                                    width: '100%',
                                    textAlign: 'center',
                                    color: '#fff',
                                }}
                            >
                                <span>
                                    Signup Below
                                    <br />
                                    <span
                                        style={{
                                            fontSize: '3em',
                                        }}
                                    >
                                        ▼
                                    </span>
                                </span>
                            </div>
                        )}
                    </div>
                </aside>
                <aside className="tile--section--inner--container">
                    <ContactForm />
                </aside>
            </div>
        </section>
    );
};

export default ContactTile;
