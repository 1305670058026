import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import MenuButton from './menu/menu_button';
import alismaTitle from '../../components/logo/alisma_logo';
// import pcTitle from '../content/title/pc-title';

class Header extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            closeMenu: false,
        };
        this.state = this.initialState;
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        if (e.target.className !== 'pc-title') {
            this.setState({
                closeMenu: true,
            });
            setTimeout(() => {
                this.setState({
                    closeMenu: false,
                });
            });
        }
    }
    render() {
        const { closeMenu } = this.state;
        return (
            <nav className="header">
                <div className="alisma-title alisma-title-light">
                    <NavLink
                        to="/"
                        title="Go to homepage"
                        onClick={this.handleClick}
                    >
                        {alismaTitle}
                    </NavLink>
                </div>
                <MenuButton open={closeMenu} />
            </nav>
        );
    }
}

export default Header;
