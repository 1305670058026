import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PassGenContent from './PassGenContent';

class PasswordGenerator extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>A L I S M A | Password Generator</title>
                    <meta name="description" content="" />
                </Helmet>
                <PassGenContent />
            </React.Fragment>
        );
    }
}

export default PasswordGenerator;
