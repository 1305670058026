import React from 'react';
export const alismaIcon = (
    <svg
        id="Layer_3"
        data-name="Layer 3"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 199.42 186.18"
        height="200px"
        width="200px"
    >
        <g id="blob">
            <path
                fill="#fff"
                d="m176.03,94.07l.08-.14c-15.79-8.37-26.55-24.96-26.55-44.08h0c0-27.53-22.32-49.86-49.86-49.86s-49.85,22.32-49.86,49.85h0c0,19.11-10.76,35.71-26.55,44.08l.08.14C9.34,102.89,0,118.51,0,136.32c0,27.53,22.32,49.86,49.86,49.86,7.82,0,15.23-1.81,21.82-5.02,7.99-5.44,17.64-8.63,28.04-8.63s20.05,3.18,28.04,8.63c6.59,3.21,13.99,5.02,21.82,5.02,27.53,0,49.86-22.32,49.86-49.86,0-17.81-9.34-33.43-23.39-42.25Z"
            />
        </g>
    </svg>
);

export default alismaIcon;
