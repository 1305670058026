import React, { Component } from 'react';
import PCScroll from './scrolling/pcscroll';
import Tile from './scrolling/tiles/tile';
import '../../styles/app.min.css';
import BackToTop from './scrolling/totop/totoparrow';
import { _qa } from '../../functions/alisma_library_react';
class Content extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loaded: false,
            tileNum: 1,
            pos: 0,
            goToTop: false,
            info: [],
            infoKeyCount: 0,
            scrollbarStyle: {
                transform: 'translate3d(0, 0px, 1px) scaleY(1)',
            },
        };
        this.state = this.initialState;
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        let infoKeyCount = Object.keys(this.props.info).length;
        let k;
        let info = this.props.info;
        for (k in this.props.info) {
            info[k]['id'] = Number(k) + 1;
        }
        this.setState({
            loaded: true,
            info: info,
            infoKeyCount: infoKeyCount,
        });
        let scrollBarY =
            (window.innerHeight * (this.state.tileNum - 1)) /
            _qa('.tile--section').length;
        setTimeout(() => {
            this.setState({
                scrollbarStyle: {
                    transform:
                        'translate3d(0, ' +
                        scrollBarY +
                        'px, 1px) scaleY(' +
                        window.innerHeight / _qa('.tile--section').length +
                        ')',
                },
            });
        }, 1);
    }
    componentWillUnmount() {
        this.setState({
            loaded: false,
        });
    }
    handleClick(x) {
        this.handleChange(0, 1);
    }
    handleChange(pos, tileNum) {
        let { infoKeyCount } = this.state;
        let scrollBarY =
            (window.innerHeight * (tileNum - 1)) / _qa('.tile--section').length;
        this.setState({ pos: pos, tileNum: tileNum });
        this.setState({
            scrollbarStyle: {
                transform:
                    'translate3d(0, ' +
                    scrollBarY +
                    'px, 1px) scaleY(' +
                    window.innerHeight / _qa('.tile--section').length +
                    ')',
            },
            goToTop: false,
        });
    }
    render() {
        const {
            loaded,
            tileNum,
            info,
            scrollbarStyle,
            pos,
            goToTop,
            infoKeyCount,
        } = this.state;
        const tileStyle = {
            transform: 'translate3d(0px,' + pos + '%, 0px)',
        };
        const bannerStyle = {
            transform:
                'translate3d(0px,' +
                -((window.innerHeight * (pos / 100)) / 1.59) +
                'px, 0px) rotateZ(-90deg)',
        };
        if (!loaded) {
            return <span id="content">...</span>;
        }
        return (
            <main id="content" className={'idx-in-' + tileNum}>
                <PCScroll
                    loaded={loaded && info}
                    onChange={this.handleChange}
                    goToTop={goToTop}
                    tileNum={tileNum}
                    tileCount={infoKeyCount}
                >
                    <article
                        id="tiles"
                        className={this.props.className}
                        style={tileStyle}
                    >
                        {info.map((item) => (
                            <Tile
                                key={item.id}
                                item={item}
                                inView={item.id === tileNum}
                                bannerStyle={bannerStyle}
                            />
                        ))}
                    </article>
                </PCScroll>
                <div id="scrollbar" style={scrollbarStyle} />

                <div id="to-top" onClick={this.handleClick}>
                    <BackToTop show={tileNum > 1} />
                </div>
            </main>
        );
    }
}
export default Content;
