import React from 'react';
import { NavLink } from 'react-router-dom';
import MultiText from './tools/multi_text';

export default class CaseTile extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loaded: false,
            item: '',
            multiText: false,
            hasImage: false,
            altInfoTop: { bottom: '50%' },
        };
        this.state = this.initialState;
        this.swapTimer = '';
        this.handleClick = this.handleClick.bind(this);
        // this.imageStyle = this.imageStyle.bind(this);
    }
    componentDidMount() {
        this.setState({
            loaded: true,
            item: this.props.item,
            multiText: this.props.item.swap,
            hasImage:
                this.props.item.imageLeft ||
                this.props.item.imageRight ||
                this.props.item.imageCenter,
        });
        // this.imageStyle();
        window.addEventListener('resize', this.imageStyle);
        window.addEventListener('orientationchange', this.imageStyle);
    }
    componentWillUnmount() {
        clearTimeout(this.swapTimer);
        window.removeEventListener('resize', this.imageStyle);
        window.removeEventListener('orientationchange', this.imageStyle);
    }

    handleClick(e) {
        // e.preventDefault();
    }

    // imageStyle() {
    //     var altInfoTop;
    //     setTimeout(() => {
    //         altInfoTop = _q('[data-idx="' + this.props.item.id + '"] .alt-info')
    //             .clientHeight;
    //         this.setState({ altInfoTop: { bottom: altInfoTop + 100 } });
    //     }, 0);
    // }

    render() {
        const { loaded, item, multiText, hasImage } = this.state;

        if (!loaded) {
            return <span>...</span>;
        }
        const year = new Date().getFullYear();
        return (
            <section
                className={
                    'tile--section tile ' +
                    item.type +
                    (this.props.inView ? ' in' : '') +
                    (!item.className ? '' : ' ' + item.className)
                }
                data-idx={item.id}
                tabIndex={item.id}
            >
                {item.background && item.background}
                {(item.backgroundImage || item.backgroundImageColor) && (
                    <img
                        className={
                            item.backgroundImageColor
                                ? 'background-image full'
                                : 'background-image'
                        }
                        alt={item.caption}
                        src={
                            '../img/' +
                            (item.backgroundImage || item.backgroundImageColor)
                        }
                    />
                )}
                {item.imageTop && (
                    <img
                        className="image-top"
                        width="30%"
                        src={'../img/' + item.imageTop}
                        alt={item.clientName}
                        title={item.clientName + ' | Case Study'}
                    />
                )}

                {(item.text || item.caption) && (
                    <div className="alt-info">
                        {item.subCaption && (
                            <p className="main-info title">
                                {item.subCaption}{' '}
                            </p>
                        )}
                        <p className="main-info">{item.caption} </p>
                        {multiText ? (
                            <MultiText text={item.text} multiText={multiText} />
                        ) : (
                            item.text
                        )}
                    </div>
                )}

                {hasImage && (
                    <div className="images">
                        <div className="image-left">
                            {item.imageLeft && (
                                <img
                                    alt={item.caption}
                                    src={'../img/' + item.imageLeft}
                                />
                            )}
                        </div>

                        <div className="image-center">
                            {item.imageCenter && (
                                <img
                                    alt={item.caption}
                                    // style={{ left: '-50%' }}
                                    src={'../img/' + item.imageCenter}
                                />
                            )}
                        </div>

                        <div className="image-right">
                            {item.imageRight && (
                                <img
                                    alt={item.caption}
                                    src={
                                        (item.className === 'bio'
                                            ? './img/'
                                            : '../img/') + item.imageRight
                                    }
                                />
                            )}
                        </div>
                    </div>
                )}

                {item.button && (
                    <NavLink to={'/' + item.path} activeStyle={this.active}>
                        <button className="button">{item.button}</button>
                    </NavLink>
                )}
                {item.type === 'footer' && (
                    <div className="copy">&copy;{year} ALISMA LLC</div>
                )}
            </section>
        );
    }
}
