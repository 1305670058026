// !
// ! Alisma Helper Library v2.0
// !

// !Selectors
export const _i = function _i(idName) {
    return document.getElementById(idName);
};
export const _q = function _q(className) {
    return document.querySelector(className);
};
export const _qa = function _q(className) {
    return document.querySelectorAll(className);
};
export const _findAncestor = function findAncestor(el, sel) {
    while (
        (el = el.parentElement) &&
        !(el.matches || el.matchesSelector).call(el, sel)
    );
    return el;
};

// !Class Name Modifiers
export const _hasClass = function (ele, cls) {
    if (ele.className) {
        return ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
    } else {
        return ele.className === cls;
    }
};
export const _addClass = function (ele, cls) {
    if (!_hasClass(ele, cls)) ele.className += ' ' + cls;
    ele.className = _trim(ele.className);
};
export const _removeClass = function (ele, cls) {
    if (_hasClass(ele, cls)) {
        var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
        ele.className = ele.className.replace(reg, ' ');
    }
    ele.className = _trim(ele.className);
};
export const _remChild = function remChild(p, c) {
    return p.removeChild(c);
};

// !Ajax Funcitons
export const _escape = function escape(str) {
    return (str + '')
        .replace(/[-[\]{}()><*+?'".,\\^$&|#\s]/g, '\\$&')
        .replace(/&/g, '%26')
        .replace(/-/g, '%2D')
        .replace(/_/g, '%5F')
        .replace(/\./g, '%2E')
        .replace(/!/g, '%21')
        .replace(/~/g, '%7E')
        .replace(/\*/g, '%2A')
        .replace(/'/g, '%27')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29');
};
export const _ajax = function ajax(params) {
    return new Promise((resolve, reject) => {
        let len = Object.keys(params.data).length;
        for (let i = 0; i < len; i++) {
            let key = Object.keys(params.data)[i];
            params.process && params.process === 'encode'
                ? (params.data[key] = encodeURIComponent(params.data[key]))
                : (params.data[key] = _escape(params.data[key]));
        }

        params.data = 'data=' + JSON.stringify(params.data);

        let xhr = new XMLHttpRequest();

        xhr.open(params.method, params.url);

        xhr.setRequestHeader(
            'Content-type',
            'application/x-www-form-urlencoded;'
        );

        xhr.onload = resolve;

        xhr.onerror = reject;

        xhr.send(params.data);
    });
};
export const _isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
        return (
            _isMobile.Android() ||
            _isMobile.BlackBerry() ||
            _isMobile.iOS() ||
            _isMobile.Opera() ||
            _isMobile.Windows()
        );
    },
};

// !Date Functions
export const _year = function _year() {
    return new Date().getFullYear();
};
export const _today = function _today() {
    var t = new Date(),
        d = t.getDate(),
        y = t.getFullYear(),
        m = t.getMonth() + 1;

    var td = String(
        String(y) +
            ('-' + (String(m).length === 1 ? '0' + m : m)) +
            ('-' + (String(d).length === 1 ? '0' + d : d))
    );

    return td;
};
export const _strDate = function _strDate(d, y) {
    var p = d.split('-');
    var nd = new Date(p[0], p[1] - 1, p[2]);
    nd = nd.toDateString();
    !y &&
        (nd = nd.slice(-4, 99) === _today().slice(0, 4) ? nd.slice(0, 10) : nd);
    return nd;
};
export const _strDateLong = function _strDateLong(d, y) {
    var p = d.split('-');
    const ordinal = function (da) {
        const dString = String(da);
        const last = +dString.slice(-2);
        if (last > 3 && last < 21) return 'th';
        switch (last % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    };
    const dayName = function (day) {
        if (day === 0) return 'Sunday';
        else if (day === 1) return 'Monday';
        else if (day === 2) return 'Tuesday';
        else if (day === 3) return 'Wednesday';
        else if (day === 4) return 'Thursday';
        else if (day === 5) return 'Friday';
        return 'Saturday';
    };
    var date = new Date(p[0], p[1] - 1, p[2]);
    var day = parseInt(p[2]) + ordinal(p[2]);
    var month = date.toLocaleString('default', {
        month: 'long',
    });
    var nd =
        dayName(date.getDay()) +
        ', ' +
        month +
        ' ' +
        day +
        (!y ? ', ' + p[0] : '');

    return nd;
};
export const _strDateMY = function _strDateMY(d) {
    var p = d.split('-');
    var date = new Date(p[0], p[1] - 1, p[2]);
    var month = date.toLocaleString('default', {
        month: 'long',
    });
    var nd = month + ', ' + p[0];

    return nd;
};
export const _monthList = function _monthList(m) {
    const mL = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    const getFull = m === '' || m === undefined || m === false || m === null;
    if (getFull) {
        return mL;
    } else {
        return mL[m];
    }
};

// !String Modifications
export const _trim = function (str) {
    return str.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
};
export const _trimText = function _trimText(txt, lng, html = false) {
    if (html === true) txt = txt.replace(/<[^>]*>?/gm, '');
    const needsTrim = txt.length > lng;
    let txtSlice = needsTrim ? txt.slice(0, lng) : txt;
    txtSlice =
        (txtSlice.slice(-1) === ' ' ? txtSlice.slice(0, -1) : txtSlice) +
        (needsTrim ? '...' : '');

    return txtSlice;
};
export const _stripTags = function _stripTags(str) {
    if (str === null || str === '') return false;
    else str = str.toString();
    str = str.replace(/(<([^>]+)>)/gi, ' ');
    str = str.replace(/\u00a0/g, ' ');
    str = str.replace(/&nbsp;/g, ' ');
    str = str.replace(/&amp;/g, '&');
    return str;
};
export const _stringPad = function _stringPad(num, len) {
    var ex = String(num);
    if (ex.length < len) {
        for (let i = 1; i < len; i++) {
            ex = '0' + ex;
        }
    }
    return ex;
};
export const _toTitleCase = function _toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

// !Scrolling Actions
export const _inView = function _inView(el, allInView = false, gap = 0.9) {
    var winHeight = window.innerHeight;
    var winWidth = window.innerWidth;
    var docHeight = document.documentElement.clientHeight;
    var docWidth = document.documentElement.clientWidth;

    var rect = el.getBoundingClientRect();
    gap = allInView ? 1 : gap;

    // !Full element is in view
    var isInView =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (winHeight * gap || docHeight * gap) &&
        rect.right <= (winWidth * gap || docWidth * gap);

    // !Element can be seen partially from the bottom
    var isPeeking =
        (rect.top <= (winHeight * gap || docHeight * gap) && rect.top >= 0) ||
        (rect.top <= (winHeight * gap || docHeight * gap) && rect.bottom >= 0);

    return allInView ? isInView : isPeeking;
};
export const _scrollWindow = function _scrollWindow(to, dur) {
    var start = new Date().getTime();
    var from = window.scrollY;

    to = Number(to);
    dur = Number(dur);

    if (isNaN(to)) to = 0;
    if (isNaN(dur)) dur = 1000;

    function ease(t, b, c, d) {
        if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
        return (-c / 2) * (--t * (t - 2) - 1) + b;
    }

    function scroll() {
        var time = new Date().getTime() - start;
        var y = ease(time, from, to - from, dur);
        window.scroll({
            top: y,
        });
        if (time <= dur) {
            requestAnimationFrame(scroll);
        } else {
            cancelAnimationFrame(scroll);
        }
    }
    requestAnimationFrame(scroll);
};

// !Miscellaneous Functions
export const _locationBarData = function locationBarData(el, sel) {
    let data = '';
    let cat = null;
    if (window.location.href.indexOf('?') > 0) {
        if (window.location.href.indexOf('&') > 0) {
            cat = window.location.href.slice(
                window.location.href.indexOf('&') + 1,
                99
            );
            data = window.location.href.slice(
                window.location.href.indexOf('?') + 1,
                window.location.href.indexOf('&')
            );
        } else {
            data = window.location.href.slice(
                window.location.href.indexOf('?') + 1,
                99
            );
        }
    }
    data = decodeURIComponent(data);
    if (cat !== null) {
        return { data: data, cat: cat };
    } else {
        return data;
    }
};
export const _randGenerator = function _randGenerator(length) {
    var result = '';
    var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * characters.length)
        );
    }
    return result;
};
