import React from 'react';
import ContactTile from '../../../../contact/contact_tiles/contact_tile';
import GraphicsPortfolioIntro from '../graphics_tiles/graphics_portfolio_intro';
import GraphicsPortfolioTile from '../graphics_tiles/graphics_portfolio_tile';

import logosApparel from '../../../../../assets/images/pages/ourwork/graphics/alisma_website_port_gfx_logos_230126_1.0@2x.png';
import flyersPostcards from '../../../../../assets/images/pages/ourwork/graphics/alisma_website_port_gfx_flyPost_230126_1.0@2x.png';
import restaurantMenus from '../../../../../assets/images/pages/ourwork/graphics/alisma_website_port_gfx_restMenus_230126_1.0@2x.png';
import misc from '../../../../../assets/images/pages/ourwork/graphics/alisma_website_port_gfx_misc_230126_1.0@2x.png';

// Default Layout
// caption: --Main info
// text: --Alt Info
// button: --Button text
// path: --React Router Path
// type: --Tile Type
// background: --SVG Background pulled via import
// background-image : --Image address
// component: <Component />

const graphicsPortfolioInfo = [
    {
        type: 'component',
        component: <GraphicsPortfolioIntro />,
    },
    {
        type: 'component',
        component: (
            <GraphicsPortfolioTile
                mainInfo="Logos & Apparel"
                sideInfo="We do not use stock imagery in your logo to make sure it is completely unique to you, and provide many different file formats so your designs can be used appropriately."
                websitePic={logosApparel}
            />
        ),
    },
    {
        type: 'component',
        component: (
            <GraphicsPortfolioTile
                mainInfo="Flyers & Postcards"
                // sideInfo="We match your brand and work with your printer to ensure the highest quality result."
                websitePic={flyersPostcards}
            />
        ),
    },
    // {
    //     type: 'component',
    //     component: (
    //         <GraphicsPortfolioTile
    //             mainInfo="Catalogs"
    //             // sideInfo="We match your brand and work with your printer to ensure the highest quality result."
    //             websitePic={ldgWP}
    //         />
    //     ),
    // },
    {
        type: 'component',
        component: (
            <GraphicsPortfolioTile
                mainInfo="Restaurant Menus"
                // sideInfo="We match your brand and work with your printer to ensure the highest quality result."
                websitePic={restaurantMenus}
            />
        ),
    },

    {
        type: 'component',
        component: (
            <GraphicsPortfolioTile
                mainInfo="Miscellaneous"
                // sideInfo="We match your brand and work with your printer to ensure the highest quality result."
                websitePic={misc}
            />
        ),
    },

    {
        type: 'component',
        component: <ContactTile />,
    },
];

export default graphicsPortfolioInfo;
