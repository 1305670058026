import React, { useEffect } from 'react';
import toggleHeaderTheme from '../../../../../functions/toggle_header_theme';
import backgroundImage from '../../../../../assets/images/cup_of_pencils.jpeg';

const WebsitesPortfolioIntro = (props) => {
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'light', menu: 'white' });
        }
        // if (props.inView && window.innerWidth < 400) {
        //     toggleHeaderTheme({ menu: 'black' });
        // }
    });
    return (
        <section className="tile--section websites-intro">
            <div
                className="tile--section--main--container"
                style={{
                    backgroundImage: 'url(' + backgroundImage + ')',
                    backgroundPosition: 'center center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <div className="tile--section--inner--container">
                    <div className="main--info--left tile--info--light">
                        <p>Websites</p>
                    </div>
                    <div className="side--info--left tile--info--light">
                        <p>
                            We offer the modern look and cutting-edge
                            functionality necessary to create a stunning website
                            that's custom to your unique business' needs.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WebsitesPortfolioIntro;
