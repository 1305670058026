import React, { useState } from 'react';

function TextInput(props) {
    const [inputValue, setInputValue] = useState();
    const handleChange = (value) => {
        props.onChange(value);
        setInputValue(value);
    };
    return (
        <div
            className={
                'form-element form-element-inline' +
                (props.required ? ' form-element-required' : '') +
                (!inputValue ? ' form-element-empty' : '') +
                (props.hasErrors.indexOf(props.id) < 0 ? '' : ' required')
            }
        >
            <div className="form-element-label">{props.label}</div>
            <div>
                <input
                    type="text"
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    // className={
                    //     props.hasErrors.indexOf(props.id) < 0
                    //         ? undefined
                    //         : 'required'
                    // }
                    disabled={props.disabled}
                    onChange={(e) => handleChange(e.target.value)}
                    onKeyPress={(e) => handleChange(e.target.value)}
                    placeholder={props.placeholder}
                    autoComplete={props.autoComplete}
                />
            </div>
        </div>
    );
}

export default TextInput;
