import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Content from '../../components/content/content';
import ourWorkInfo from './ourwork_info/ourwork_info';

class OurWork extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>A L I S M A - Our Work</title>
                    <meta
                        name="description"
                        content="Imagine easily being able to reach intended audiences and increasing brand recognition to bring in new customers."
                    />
                </Helmet>
                <Content info={ourWorkInfo} className="our-work" />
            </React.Fragment>
        );
    }
}

export default OurWork;
