import React, { useEffect, useState } from 'react';
import { _year } from '../../../functions/alisma_library_react';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';
import ContactTileFooter from './contact_tile_footer';
import ContactTileHeadshots from './contact_tile_headshots';
import ContactTileInfo from './contact_tile_info';
import ContactForm from './form/ContactForm';

const ContactTile = (props) => {
    const [splitContactTile, setSplitContactTile] = useState(
        window.innerWidth < 1000
    );
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'dark' });
            if (window.innerWidth < 1000) {
                toggleHeaderTheme({ menu: 'dark' });
            }
        }

        const adjustSize = () => {
            if (window.innerWidth < 1000) {
                setSplitContactTile(true);

                if (props.inView) {
                    toggleHeaderTheme({ menu: 'dark' });
                }
            } else {
                setSplitContactTile(false);
                if (props.inView) {
                    toggleHeaderTheme({ menu: 'light' });
                }
            }
        };

        window.addEventListener('resize', adjustSize);

        return function cleanUp() {
            window.removeEventListener('resize', adjustSize);
        };
    });
    return (
        <section className="tile--section contact-tile">
            <div className="tile--section--main--container">
                <aside className="tile--section--inner--container">
                    <ContactForm />
                </aside>

                <aside
                    className={
                        'tile--section--inner--container tile--section--media--container' +
                        (!splitContactTile ? '' : ' tile--section')
                    }
                >
                    <div>
                        <ContactTileHeadshots />
                        <ContactTileInfo />
                        <ContactTileFooter />
                    </div>
                </aside>
            </div>
        </section>
    );
};

export default ContactTile;
