import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Content from '../../components/content/content';
import whatWeDoInfo from './whatwedo_info/whatwedo_info';

class WhatWeDo extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>A L I S M A - Discover</title>
                    <meta
                        name="description"
                        content="A fresh website allows for a more extensive advertising scale to reach your target market the right way"
                    />
                </Helmet>
                <Content info={whatWeDoInfo} />
            </React.Fragment>
        );
    }
}

export default WhatWeDo;
