import React from 'react';
import { Motion, spring } from 'react-motion';
import { rmTiming } from '../../../../functions/rm_timing';

class BackToTop extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
        };
        this.handleToggle = this.handleToggle.bind(this);
    }
    handleToggle() {
        this.setState({ active: !this.state.active });
    }
    componentDidMount() {
        this.setState({ active: this.props.active });
    }
    render() {
        // const active = this.props.active;

        const totalLength = 790;
        const hideLength = -790;
        const showLength = 325;

        const defaultSpring = -totalLength;

        const timingSet = rmTiming(3);
        // const timingSet = {
        //     stiffness: timing[0],
        //     damping: timing[1],
        //     precision: 0.0000001,
        // };

        const timingSetFast = rmTiming(1);
        // const timingSetFast = {
        //     stiffness: timingFast[0],
        //     damping: timingFast[1],
        //     precision: 0.0000001,
        // };

        const hideSpring = spring(hideLength, timingSet);
        const showSpring = spring(showLength, timingSet);

        return (
            <svg
                id="back-to-the-top"
                // className={!this.props.show ? undefined : 'in'}
                viewBox="0 0 270.74 126.2"
                // onClick={this.handleToggle}
            >
                <g style={{ fillOpacity: 0 }}>
                    <Motion
                        defaultStyle={{ offset: defaultSpring, cirScale: 0 }}
                        style={{
                            offset: !this.props.show ? hideSpring : showSpring,
                            cirScale: !this.props.show
                                ? spring(0, timingSetFast)
                                : spring(1, timingSet),
                        }}
                    >
                        {(style) => (
                            <React.Fragment>
                                <circle
                                    className="cls-2"
                                    cx="53.89"
                                    cy="53.89"
                                    r="53.89"
                                    transform={`translate(8 8) scale(${style.cirScale})`}
                                />
                                <path
                                    className="cls-1"
                                    strokeDasharray={`${totalLength} ${totalLength}`}
                                    strokeDashoffset={style.offset}
                                    d="M94,72.6,56.76,38.05,20.31,75s-15,2-17.57-1.57C-2.26,51.65,0,34.52,14.82,18.11A57.75,57.75,0,0,1,58.11,0c31,0,54.09,25.12,54.09,56.1A56.1,56.1,0,1,1,56.09,0H263.74"
                                    transform="translate(8 8)"
                                />
                            </React.Fragment>
                        )}
                    </Motion>
                </g>
            </svg>
        );
    }
}

// <svg
//     id="back-to-the-top"
//     data-name="Layer 1"
//     viewBox="0 0 270.74 126.2"
// >
//     <title>ring</title>
//     <path
//         className="cls-1"
//         // d="M58.11,0c31,0,54.09,25.12,54.09,56.1A56.1,56.1,0,1,1,56.09,0H263.74"
//         d="M94,72.6,56.76,38.05,20.31,75s-15,2-17.57-1.57C-2.26,51.65,0,34.52,14.82,18.11A57.75,57.75,0,0,1,58.11,0c31,0,54.09,25.12,54.09,56.1A56.1,56.1,0,1,1,56.09,0H263.74"
//         transform="translate(7 7)"
//     />
// </svg>

export default BackToTop;
