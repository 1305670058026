import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
// import alismaIcon from '../../../components/logo/alisma_icon';
import alismaIconAlt from '../../../components/logo/alisma_icon';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';

const AboutPreview = (props) => {
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'dark', menu: 'white' });
        }
        if (props.inView && window.innerWidth < 400) {
            toggleHeaderTheme({ menu: 'black' });
        }
    });
    return (
        <section className="tile--section home-aboutpreview">
            <div className="tile--section--main--container">
                <div className="tile--section--inner--container">
                    <div className="main--info--left tile--info--dark">
                        <p>We are a husband and wife design team. </p>
                    </div>
                    <div className="side--info--left tile--info--dark">
                        <p>
                            We have a serious passion for crafting beautiful
                            graphics and easy to use websites.
                        </p>
                        <NavLink to="/about" title="Learn More">
                            <button className="button">Learn More</button>
                        </NavLink>
                    </div>
                </div>
                <div className="tile--section--inner--container tile--section--media--container">
                    <div>
                        <div
                            className={
                                'home-aboutpreview-icon' +
                                (props.inView ? ' in' : '')
                            }
                        >
                            {alismaIconAlt}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutPreview;
