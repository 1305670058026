import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';
import photoGroup from '../../../assets/images/pages/home/photo_group_1.png';

const ProcessPreview = (props) => {
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'light' });
        }
        if (props.inView && window.innerWidth < 400) {
            toggleHeaderTheme({ menu: 'white' });
        } else if (props.inView && window.innerWidth > 400) {
            toggleHeaderTheme({ menu: 'black' });
        }
    });
    return (
        <section className="tile--section home-processpreview">
            <div className="tile--section--main--container">
                <div className="tile--section--inner--container tile--section--media--container">
                    <div>
                        <img src={photoGroup} alt="" />
                    </div>
                </div>
                <div className="tile--section--inner--container">
                    <div className="main--info--right tile--info--dark">
                        <p>From idea conception to product delivery. </p>
                    </div>
                    <div className="side--info--right tile--info--dark">
                        <p>
                            There is no ‘one size fits all’ solution; we are
                            dedicated to helping you discover your style and
                            bring your branding to life.
                        </p>
                        <NavLink to="/what-we-do" title="Discover How">
                            <button className="button">Discover How</button>
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProcessPreview;
