import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import toggleHeaderTheme from '../../../functions/toggle_header_theme';
import photoGroup from '../../../assets/images/pages/whatwedo/photo_group_4.png';

const UserExperience = (props) => {
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'light' });
        }
        if (props.inView && window.innerWidth < 400) {
            toggleHeaderTheme({ menu: 'white' });
        } else if (props.inView && window.innerWidth > 400) {
            toggleHeaderTheme({ menu: 'black' });
        }
    });
    return (
        <section className="tile--section what-we-do-user-experience">
            <div className="tile--section--main--container">
                <div className="tile--section--inner--container tile--section--media--container">
                    <div>
                        <img src={photoGroup} alt="" />
                    </div>
                </div>
                <div className="tile--section--inner--container">
                    <div className="main--info--right tile--info--dark">
                        <p>We create a seamless user experience.</p>
                    </div>
                    <div className="side--info--right tile--info--dark">
                        <p>
                            Our beautifully crafted intuitive designs have
                            helped businesses engage thousands of people in
                            their target market every day.
                        </p>
                        <NavLink to="/our-work" title="Our Work">
                            <button className="button">Our Work</button>
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UserExperience;
