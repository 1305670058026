import React from 'react';

export default class DayInput extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            day: '',
            total: '',
        };
        this.state = this.initialState;
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        this.setState({
            day: this.props.day,
            total: this.props.total,
        });
    }
    componentWillReceiveProps() {
        this.setState({
            total: this.props.total,
        });
    }
    handleChange(e) {
        this.props.onChange(e.target.value, this.state.day);
    }
    render() {
        const { day, total } = this.state;
        const styleLeft = {
            display: 'inline-block',
            width: 100,
            fontFamily: 'Verdana',
            fontSize: '12px',
            textAlign: 'right',
            padding: 8,
        };
        const styleInput = {
            display: 'inline-block',
            width: 80,
            fontFamily: 'Verdana',
            fontSize: '16px',
            textAlign: 'center',
            padding: 8,
            border: '1px solid transparent',
            borderBottom: '1px solid #eaeaea',
            backgroundColor: 'transparent',
            borderRadius: 0,
            outline: '0px',
        };
        const styleRight = {
            display: 'inline-block',
            width: 80,
            fontFamily: 'Verdana',
            fontSize: '12px',
            textAlign: 'left',
            padding: 8,
        };

        return (
            <div className="day-input" style={{ maxWidth: '300px' }}>
                <div style={styleLeft}>{day}:</div>
                <input
                    style={styleInput}
                    onChange={this.handleChange}
                    onKeyPress={this.handleChange}
                    type="number"
                    name="day"
                />
                {/* &nbsp;|&nbsp; */}
                <div style={styleRight}>{' ' + total}</div>
            </div>
        );
    }
}
