// import caseFooterInfo from './caseFooterInfo';

// **Default Layout**
// className: --Class given to specific tiles
// caption: --Main info
// text: --Alt Info
// fatText: --Fatter text than alt text (white also)
// button: --Button text
// path: --React Router Path
// clientBannerName: --Name of Client fot side banner - FIRST ITEM ONLY!
// clientName: --Name of Client fot identifying and alt tags
// clientLogo: --Name to image file containing logo
// imageLeft: --image stuck to left side of screen
// imageCenter: --slightly larger image in center of screen
// imageRight: --image stuck to right side of screen
// type: --Tile Type
// background: --SVG Background pulled via import
// backgroundImage: --Raster Background pulled via IMG tag
// backgroundImageColor: --Raster Background pulled via IMG tag in full color

const hoofhaulersInfo = [
    {
        caption: 'Love. Horses. Trailers.',
        text: '',
        backgroundImageColor: 'cases/hoofhaulers/backgrounds/woman_horse.jpg',
        clientBannerName: 'HoofHaulers',
        type: 'case',
    },
    {
        className: 'about',
        caption: '',
        text: 'Action packed, patriotic, and easy to use giving an exciting and dynamic visitor experience.',
        imageLeft: 'client_logos/hh_logo.png',
        imageCenter: 'cases/hoofhaulers/hh_homepage_window.png',
        type: 'case',
    },
    {
        className: 'browse',
        caption: 'At Their Own Pace',
        text: 'Browsing comfortably with customizable filtering, sorting, and view sizes. Visitors shop with ease.',
        imageLeft: 'cases/hoofhaulers/devices/hh_iMac_browse_1.0.png',
        imageRight: 'cases/hoofhaulers/devices/hh_mbp_shadow_1.0.png',
        type: 'case',
    },
    {
        className: 'inform',
        caption: '',
        text: 'Informative all around, along with allowing for showcasing items of choice, allows visitors to know this is a company to trust.',
        backgroundImageColor:
            'cases/hoofhaulers/devices/hh_ipad_hand_woodtable_1.0.jpg',
        type: 'case',
    },
    {
        className: 'admin',
        caption: 'Not Just Diamonds',
        text: 'Being able to manage and maintain directly from the page means this site is in it for the long haul...because forever is a long time.',
        imageLeft: 'cases/hoofhaulers/devices/hh_mbp_ipad_admin_1.0.png',
        imageRight: 'cases/hoofhaulers/devices/hh_iMac_admin_1.0.png',
        type: 'case',
    },
    {
        className: 'result',
        caption: 'The Result',
        text: 'A smoother shopping experience, easier to maintain inventory, and stylish overall design almost instantly achieved the highest web traffic in just shy of ten years.',
        // imageLeft: 'cases/hoofhaulers/devices/hh_mbp_ipad_admin_1.0.png',
        backgroundImageColor: 'cases/hoofhaulers/devices/hh_iphones_4_1.0.jpg',
        type: 'case',
    },
    // caseFooterInfo
];

export default hoofhaulersInfo;
