import React from 'react';
import ContactTile from '../contact_tiles/contact_tile';

// Default Layout
// caption: --Main info
// text: --Alt Info
// button: --Button text
// path: --React Router Path
// type: --Tile Type
// background: --SVG Background pulled via import
// background-image : --Image address
// component: <Component />

const contactInfo = [{ type: 'component', component: <ContactTile /> }];
// const contactInfo =
//     window.innerWidth < 800
//         ? [
//               { type: 'component', component: <ContactTile formOnly={true} /> },
//               {
//                   type: 'component',
//                   component: <ContactTile infoOnly={true} />,
//               },
//           ]
//         : [{ type: 'component', component: <ContactTile /> }];

export default contactInfo;
