import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Content from '../../components/content/content';
import homeInfo from './home_info/home_info';

class Home extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>A L I S M A</title>
                    <meta
                        name="description"
                        content="We have a passion for creating beautiful graphics and intuitive websites, this is what we do best! Let us show you how we can help with your next project."
                    />
                </Helmet>
                <Content info={homeInfo} className="home" />
            </React.Fragment>
        );
    }
}

export default Home;
