import React from 'react';
import { NavLink } from 'react-router-dom';
import MultiText from './tools/multi_text';

export default class MainTile extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loaded: false,
            item: '',
            multiText: false,
        };
        this.state = this.initialState;
        this.swapTimer = '';
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        this.setState({
            loaded: true,
            item: this.props.item,
            multiText: this.props.item.swap,
        });
    }
    componentWillUnmount() {
        clearTimeout(this.swapTimer);
    }

    handleClick(e) {
        // e.preventDefault();
    }

    render() {
        const { loaded, item, multiText } = this.state;

        if (!loaded) {
            return <span>...</span>;
        }
        const year = new Date().getFullYear();
        return (
            <section
                className={
                    'tile tile--section ' +
                    item.type +
                    (this.props.inView ? ' in' : '')
                }
                data-idx={item.id}
                tabIndex={item.id}
            >
                {item.background}
                {item.backgroundImage && (
                    // <div
                    //     className="background-image"
                    //     style={{
                    //         background:
                    //             'url(./img/' + item.backgroundImage + ')',
                    //         backgroundSize: 'cover',
                    //         backgroundRepeat: 'no-repeat',
                    //         backgroundPosition: 'center, center',
                    //         width: '100%',
                    //         height: '100%',
                    //     }}
                    // ></div>
                    <img
                        className="background-image"
                        alt={item.caption}
                        src={'./img/' + item.backgroundImage}
                    />
                )}
                {item.caption && <p className="main-info">{item.caption} </p>}

                {item.image && (
                    <img alt={item.caption} src={'./img/' + item.image} />
                )}
                {item.text && (
                    <p className="alt-info">
                        {multiText ? (
                            <MultiText text={item.text} multiText={multiText} />
                        ) : (
                            item.text
                        )}
                    </p>
                )}
                {item.button && (
                    <NavLink to={'/' + item.path} activeStyle={this.active}>
                        <button className="button">{item.button}</button>
                    </NavLink>
                )}
                {item.type === 'footer' && (
                    <div className="copy">
                        All Rights Reserved.
                        <br />
                        &copy;{year} ALISMA LLC
                    </div>
                )}
            </section>
        );
    }
}
