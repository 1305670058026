import React from 'react';
import ContactTile from '../../contact/contact_tiles/contact_tile';
import OurWorkDigitalMarketing from '../ourwork_tiles/ourwork_digitial_marketing';
import OurWorkGraphics from '../ourwork_tiles/ourwork_graphics';
import OurWorkIntro from '../ourwork_tiles/ourwork_intro';
import OurWorkWebsites from '../ourwork_tiles/ourwork_websites';

// Default Layout
// caption: --Main info
// text: --Alt Info
// button: --Button text
// path: --React Router Path
// type: --Tile Type
// background: --SVG Background pulled via import
// background-image : --Image address
// component: <Component />

const ourWorkInfo = [
    {
        type: 'component',
        component: <OurWorkIntro />,
    },
    {
        type: 'component',
        component: <OurWorkGraphics />,
    },
    {
        type: 'component',
        component: <OurWorkWebsites />,
    },
    {
        type: 'component',
        component: <OurWorkDigitalMarketing />,
    },
    {
        type: 'component',
        component: <ContactTile />,
    },
];

export default ourWorkInfo;
