import React from 'react';
import './_select.css';

const Select = (props) => {
    // useEffect(() => {
    //     props.selected && setSelected(props.selected);
    //     console.log(selected);
    // }, [selected, props.selected, props.isMulti]);
    const style = {};

    props.buttonColor && (style.backgroundColor = props.buttonColor);
    props.borderColor && (style.borderColor = props.borderColor);

    return (
        <div className="select-it">
            {props.label && <div className="select-label">{props.label}</div>}
            <span
                className={
                    'sel' +
                    (props.hasErrors
                        ? props.hasErrors.indexOf(props.id) < 0
                            ? ''
                            : ' required'
                        : '')
                }
            >
                <select
                    style={style}
                    value={props.selectedOption}
                    onChange={(e) => {
                        props.onChange(e.target.value);
                    }}
                    className="themed-background themed-text"
                >
                    {props.placeholder && (
                        <option value="" disabled selected>
                            {props.placeholder}
                        </option>
                    )}

                    {props.additionalOptions &&
                        props.additionalLocation === 'top' && (
                            <React.Fragment>
                                {props.additionalOptions.map((opt, idx) => (
                                    <option
                                        key={idx}
                                        value={
                                            props.valueType === 'index'
                                                ? idx + 1
                                                : opt
                                        }
                                    >
                                        {opt}
                                    </option>
                                ))}
                                <optgroup></optgroup>
                            </React.Fragment>
                        )}
                    {props.showAll && (
                        <React.Fragment>
                            <option>{props.showAll}</option>
                            <optgroup></optgroup>
                        </React.Fragment>
                    )}
                    {!props.showAll && !props.noBlank && <option></option>}
                    {props.options.map((opt, idx) => (
                        <option
                            key={idx}
                            value={props.valueType === 'index' ? idx + 1 : opt}
                        >
                            {opt}
                        </option>
                    ))}
                    {props.additionalOptions &&
                        props.additionalLocation !== 'top' && (
                            <React.Fragment>
                                <optgroup></optgroup>
                                {props.additionalOptions.map((opt, idx) => (
                                    <option
                                        key={idx}
                                        value={
                                            props.valueType === 'index'
                                                ? idx + 1
                                                : opt
                                        }
                                    >
                                        {opt}
                                    </option>
                                ))}
                            </React.Fragment>
                        )}
                </select>
            </span>
        </div>
    );
};

// !Example
// <Select
//     id="selectId"
//     label="Label"
//     options={['Option 1', 'Option 2', 'Option 3']}
//     additionalOptions={['Option 4', 'Option 5']}
//     showAll={true}
//     noBlank={true}
//     selectedOption={selectInfo}
//     hasErrors={hasErrors}
//     valueType="index"
//     onChange={(value) => setSelectInfo(value)}
// />;

export default Select;
