import React from 'react';
// import { NavLink } from 'react-router-dom';
import ClientTile from './client_tile';
import CaseTile from './case_tile';
import MainTile from './main_tile';
// import ComponentTile from './component_tile';

export default class Tile extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = {
            loaded: false,
        };
        this.state = this.initialState;
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        this.setState({
            loaded: true,
        });
    }
    handleClick(e) {}
    render() {
        const { loaded } = this.state;
        const { item } = this.props;

        if (!loaded) {
            return <span>...</span>;
        } else if (item.type === 'client') {
            return <ClientTile {...this.props} />;
        } else if (item.type === 'case') {
            return (
                <React.Fragment>
                    {this.props.item.clientBannerName && (
                        <div
                            className="client-banner"
                            style={this.props.bannerStyle}
                        >
                            {this.props.item.clientBannerName}
                        </div>
                    )}
                    <CaseTile {...this.props} />
                </React.Fragment>
            );
        } else if (item.type === 'component') {
            // return <ComponentTile {...this.props} />;
            const updateChildrenWithProps = React.Children.map(
                this.props.item.component,
                (child, i) => {
                    return React.cloneElement(child, {
                        inView: this.props.inView,
                    });
                }
            );
            return updateChildrenWithProps;
        } else {
            return <MainTile {...this.props} />;
        }
    }
}
