import React, { useState } from 'react';
import { _pathApi } from '../../../../functions/controls/app_paths';
import { _ajax, _qa } from '../../../../functions/alisma_library_react';
import FormControls from './FormControls';
import FormContent from './FormContent';

function ContactForm() {
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formValidating, setFormValidating] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [hasErrors, setHasErrors] = useState([]);
    const [progress, setProgress] = useState(0);
    const [formInfo, setFormInfo] = useState({});

    var progressTimer = false;

    const submitForm = () => {
        setProgress(10);
        progressTimer = setTimeout(() => {
            if (!formSubmitting) {
                setFormSubmitting(true);
                validateForm();
            }
        }, 100);
    };
    const validateForm = () => {
        setProgress(20);
        progressTimer = setTimeout(() => {
            if (!formValidating) {
                setFormValidating(true);

                // !Gather Required Inputs
                const requiredElements = _qa(
                    '.form-element-required input, .form-element-required textarea'
                );
                let requiredInputs = [];
                for (let i = 0; i < requiredElements.length; i++) {
                    requiredInputs.push(requiredElements[i].id);
                }

                let verifyInputs = [];
                let errors = [];

                // !Validate inputs
                setProgress(30);

                progressTimer = setTimeout(() => {
                    for (let i = 0; i < requiredInputs.length; i++) {
                        const req = formInfo[requiredInputs[i]];
                        if (requiredInputs[i] === 'email') {
                            verifyInputs.push(
                                (req &&
                                    req.length > 5 &&
                                    req.indexOf('@') >= 1) ||
                                    false
                            );
                        } else {
                            verifyInputs.push(
                                (req && (req.length > 1 || req === true)) ||
                                    false
                            );
                        }
                    }
                }, 100);

                // !Record errors
                setProgress(40);

                progressTimer = setTimeout(() => {
                    for (let i = 0; i < requiredInputs.length; i++) {
                        if (verifyInputs[i] === false) {
                            errors.push(requiredInputs[i]);
                        }
                    }
                }, 100);

                setProgress(50);

                progressTimer = setTimeout(() => {
                    const isVerified = verifyInputs.indexOf(false) < 0;

                    if (isVerified) {
                        setProgress(60);
                        sendInfo(isVerified);
                        setHasErrors([]);
                    } else {
                        setProgress(0);
                        clearTimeout(progressTimer);
                        setHasErrors(errors);
                        setFormSubmitting(false);
                        setFormValidating(false);
                    }
                }, 100);
            }
        }, 100);
    };

    const sendInfo = (isVerified) => {
        setProgress(70);
        let newFormInfo = {};
        for (let key in formInfo) {
            newFormInfo[key] = formInfo[key];
        }
        progressTimer = setTimeout(() => {
            if (isVerified) {
                setProgress(80);

                progressTimer = setTimeout(() => {
                    _ajax({
                        method: 'POST',
                        url: _pathApi + 'forms/form_contact.php',
                        data: newFormInfo,
                    }).then((e) => {
                        setProgress(90);

                        progressTimer = setTimeout(() => {
                            setFormSubmitted(true);
                            const inputs = Object.keys(formInfo);
                            for (let i = 0; i < inputs.length; i++) {
                                setFormInfo({ [inputs[i]]: '' });
                            }
                            setProgress(100);
                            setFormInfo({});
                            progressTimer = setTimeout(() => {
                                clearTimeout(progressTimer);
                                setProgress(0);
                            }, 500);
                        }, 100);
                    });
                }, 100);
            }
        }, 100);
    };
    return (
        <form
            className="form--main--section contact-form"
            onSubmit={(e) => e.preventDefault()}
        >
            <FormContent
                formInfo={formInfo}
                formSubmitting={formSubmitting}
                formSubmitted={formSubmitted}
                progress={progress}
                hasErrors={hasErrors}
                onChange={(id, value) =>
                    setFormInfo({ ...formInfo, [id]: value })
                }
            >
                <FormControls
                    formSubmitting={formSubmitting}
                    formSubmitted={formSubmitted}
                    progress={progress}
                    hasErrors={hasErrors}
                    onClick={() => submitForm()}
                />
            </FormContent>
        </form>
    );
}

export default ContactForm;
