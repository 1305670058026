import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import toggleHeaderTheme from '../../../../../functions/toggle_header_theme';

const DigitalMarketingPortfolioTile = (props) => {
    useEffect(() => {
        if (props.inView) {
            toggleHeaderTheme({ logo: 'white', menu: 'white' });
        }
    });
    return (
        <section className="tile--section websites-portfolio">
            <div className="tile--section--main--container">
                <div className="tile--section--inner--container">
                    <div className="main--info--left tile--info--light">
                        <p>{props.mainInfo}</p>
                        {props.caseLink && (
                            <NavLink to={props.caseLink}>
                                <button>Case Study</button>
                            </NavLink>
                        )}
                    </div>
                    <div className="side--info--left tile--info--light">
                        <p>{props.sideInfo}</p>
                    </div>
                </div>
                <div className="tile--section--inner--container tile--section--media--container">
                    <div>
                        <img src={props.websitePic} alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DigitalMarketingPortfolioTile;
