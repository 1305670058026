import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Content from '../../../../components/content/content';
import graphicsPortfolioInfo from './graphics_info/graphics_portfolio_info';

class GraphicsPortfolio extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>A L I S M A - Our Work - Graphics</title>
                    <meta
                        name="description"
                        content="Imagine easily being able to reach intended audiences and increasing brand recognition to bring in new customers."
                    />
                </Helmet>
                <Content info={graphicsPortfolioInfo} className="our-work" />
            </React.Fragment>
        );
    }
}

export default GraphicsPortfolio;
