import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Content from '../../components/content/content';
import contactInfo from './contact_info/contact_info';

class Giveaway2304 extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>A L I S M A - Giveaway - April 2023</title>
                    <meta
                        name="description"
                        content="We listen because we care. We understand the importance in building a high degree of trust and excellence in the products and services we provide."
                    />
                </Helmet>
                <Content info={contactInfo} className="contact" />
            </React.Fragment>
        );
    }
}

export default Giveaway2304;
