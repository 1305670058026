import React, { Component } from 'react';
import { Motion, spring } from 'react-motion';
import { interpolate } from 'flubber';
import { rmTiming } from '../../functions/rm_timing';

var heart =
    'm73.96,9.89h0c-8.75-7.1-21.71-5.75-28.81,3l-2.16,2.66-2.16-2.66c-7.1-8.75-20.07-10.1-28.81-3h0c-8.75,7.1-10.1,20.07-3,28.81l7.59,9.35,26.38,32.51,26.38-32.51,7.59-9.35c7.1-8.75,5.75-21.71-3-28.81Z';

var alismaLogo =
    'm73.83,43.36l.03-.05c-6.38-3.38-10.73-10.09-10.73-17.82,0-11.13-9.03-20.16-20.16-20.16s-20.16,9.02-20.16,20.16c0,7.73-4.35,14.44-10.73,17.82l.03.05c-5.68,3.57-9.46,9.88-9.46,17.08,0,11.13,9.02,20.16,20.16,20.16,3.16,0,6.16-.73,8.82-2.03,3.23-2.2,7.13-3.49,11.34-3.49s8.11,1.29,11.34,3.49c2.66,1.3,5.66,2.03,8.82,2.03,11.13,0,20.16-9.02,20.16-20.16,0-7.2-3.78-13.52-9.46-17.08';

class AlismaToHeart extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            posi: 0,
            posiSh: 0,
            shape: 1,
            clicked: false,
            restCount: 0,
        };
        this.state = this.initialState;
        this.splatInit = this.splatInit.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        this.splatInit();
    }
    splatInit() {
        setTimeout(() => {
            this.setState({ posi: 1, posiSh: 1 });
        }, 1500);
    }
    handleClick() {
        console.log('clicked');
        this.setState({ posi: 0, clicked: true });
        this.splatInit();
    }
    render() {
        const { clicked } = this.state;
        const timing = rmTiming(2);

        var mainInterpolator = interpolate(
            !clicked ? alismaLogo : alismaLogo,
            heart,
            {
                maxSegmentLength: 3,
            }
        );

        return (
            <svg
                id="alisma-pcd"
                width="85.93"
                height="85.93"
                viewBox="0 0 85.93 85.93"
            >
                <title>Polarcode to Alisma</title>

                <Motion
                    defaultStyle={{ morph: 0, spin: 0, r: 255, g: 255, b: 255 }}
                    style={{
                        morph: !this.props.show
                            ? 0
                            : spring(this.state.posi, timing),

                        spin: !this.props.show ? 0 : spring(360, timing),
                    }}
                >
                    {(style) => (
                        <React.Fragment>
                            <g
                                className="alismaSplatLogo"
                                onClick={this.handleClick}
                            >
                                <path d={mainInterpolator(style.morph)} />
                            </g>
                        </React.Fragment>
                    )}
                </Motion>
            </svg>
        );
    }
}

export default AlismaToHeart;
